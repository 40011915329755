<template>
  <el-container>
    <el-dialog :title="titType" :visible.sync="addMemberShow" width="30%" @close="close">
      <div class="content">
        <div class="input_item">
          <div class="item_vlaue">成员姓名{{ titType }}</div>
          <div class="item_key">
            <el-input v-model="formData.name" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">账号</div>
          <div class="item_key">
            <el-input v-model="formData.account" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">联系电话</div>
          <div class="item_key">
            <el-input v-model="formData.mobile" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">登录密码</div>
          <div class="item_key">
            <el-input type="password" v-model="formData.password" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="input_item" v-if="formData.roleName != '超级管理员'">
          <div class="item_vlaue">所属权限</div>
          <div class="item_key">
            <el-radio-group v-model="formData.roleName">
              <el-radio label="监控人员">监控人员</el-radio>
              <el-radio label="值班人员">值班人员</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- <div class="input_item" style="background: #fbe9e9; padding: 16px; margin-top: 24px">
          <div>
            <div>权限说明：</div>
            <div>
              1、监控人员：负责接听和处理警情 <br />
              2、值班人员：只可登录APP，用于处理警情
            </div>
          </div>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="beSure">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addMemberShow', 'Ok', 'titType', 'recordData'],
  data() {
    return {
      radio: 1,
      formData: {
        name: '',
        mobile: '',
        account: '',
        password: '',
        roleName: ''
      }
    }
  },
  watch: {
    recordData(n, o) {
      this.formData = this.recordData
    }
  },
  methods: {
    close() {
      this.$emit('closeAddMember')
    },
    beSure() {
      this.Ok(this.formData, this.formData.id)
      this.close()
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
}
</style>
