<template>
  <el-container>
    <el-header class="header">呼出记录</el-header>
    <el-main>
      <!-- 精准查询 -->
      <!-- <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">楼号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">单元</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div> -->
      <!-- 列表 -->
      <div class="list">
        <!-- 打开外呼记录弹框 -->
        <addRecord :addRecord="recordShow" @closeAddRecord="showRecord"></addRecord>
        <el-table :data="tableData && tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID"> </el-table-column>
          <!-- TODOOOOO -->
          <el-table-column prop="name" label="房号"> </el-table-column>
          <el-table-column prop="userName" label="用户姓名"> </el-table-column>
          <el-table-column prop="calledTelephone" label="被叫电话"> </el-table-column>
          <!-- TODOOOOO -->
          <el-table-column prop="name" label="主叫电话"> </el-table-column>
          <el-table-column prop="outStartTime" label="外呼开始时间"> </el-table-column>
          <el-table-column prop="outEndTime" label="外呼结束时间"> </el-table-column>
          <el-table-column prop="callRecord" label="通话记录">
            <template slot-scope="scope">
              <el-button @click.native.prevent="playRow(scope.$index, tableData)" type="text" size="small">
                播放
              </el-button>
            </template>
          </el-table-column>
          <!-- TODOOOOO -->
          <el-table-column prop="address" label="外呼记录"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="callbackRow(scope.row)" type="text" size="small"> 回拨 </el-button>
              <el-button @click.native.prevent="recordRow(scope.$index, tableData)" type="text" size="small">
                记录
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 隐私电话弹框 -->
      <privacyCall
        :privacyCall="privacyCallShow"
        :record="curRecord"
        :isCallRecord="true"
        @closePrivacyCall="showPrivacyCall"
      ></privacyCall>
    </el-main>
  </el-container>
</template>
<script>
import AddRecord from '@/components/traffic/addRecord'
import PrivacyCall from '@/components/public/privacyCall'
import { queryCallOuter } from '@/api/call.js'

export default {
  props: ['addOpen'],
  components: {
    addRecord: AddRecord,
    privacyCall: PrivacyCall
  },
  data() {
    return {
      recordShow: false,
      privacyCallShow: false,
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      tableData: [],
      query: {
        page: 1,
        pageSize: 10
      },
      curRecord: ''
    }
  },
  created() {
    this.init()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryCallOuter(this.query)
      if (result.success) {
        this.tableData = result.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    showRecord() {
      this.recordShow = false
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    playRow(index) {
      // console.log(index)
    },
    callbackRow(record) {
      // console.log(record)
      this.curRecord = record
      this.privacyCallShow = true
    },
    recordRow(index) {
      // console.log(index)
      this.recordShow = true
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
