<template>
  <el-container>
    <el-dialog title="查看营业执照" :visible.sync="businessLicense" width="70%" @close="close" class="abow_dialog">
      <div class="content" style="width: 100%">
        <img :src="imgSrc" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="businessLicense = false">关闭</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['businessLicense', 'imgSrc'],
  data() {
    return {
      form: {
        businessLicense: ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeBusinessLicense')
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
}
</style>
