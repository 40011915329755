<template>
  <el-container>
    <el-header class="header">来电预警</el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ callAllTotal }}</div>
                <div>来电总次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9f2fb">
              <div class="grid_inner">
                <div class="data_number">{{ thisMonthEventTotal }}</div>
                <div>本月新增</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ callPropertyTotal }}</div>
                <div>物业处理次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9f2fb">
              <div class="grid_inner">
                <div class="data_number">{{ callOperateTotal }}</div>
                <div>平台处理次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ callUpPaidTotal }}</div>
                <div>未接听次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ eventTimeOutTotal }}</div>
                <div>超时处理</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ eventSeriousTimeOutTotal }}</div>
                <div>严重超时处理</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 精准查询 -->
      <!-- <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">楼号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">单元</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div> -->
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="roomName" label="房号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.floorName }}楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                {{ scope.row.roomName }}室
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="用户姓名" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ userNameStr(scope.row.userName) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="incomingCall" label="来电电话" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ mobileStr(scope.row.incomingCall) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="calledTelephone" label="被叫电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="callStartTime" label="来电开始时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.callStartTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="callEndTime" label="来电结束时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.callEndTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="通话记录" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-button @click.native.prevent="playRow(scope.$index, tableData)" type="text" size="small">
                播放
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="processingStatus" label="处理状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.processingStatus == null ? '-' : '已处理' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="processingRecord" label="处理记录" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="resolver" label="解决方" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.resolver == null ? '-' : scope.row.resolver }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="processingTime" label="处理时长" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p v-if="scope.row.processingTime == null || scope.row.processingTime == ''">正常</p>
              <p v-if="scope.row.processingTime == 1" :style="{ color: color }">超时</p>
              <p v-if="scope.row.processingTime == 2" :style="{ color: color }">严重超时</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                回拨
              </el-button>
              <el-button @click.native.prevent="solveRow(scope.$index, tableData)" type="text" size="small">
                解决
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 打开解决弹框 -->
      <callSolve :callSolve="callSolveShow" @closeCallSolve="showCallSolve"></callSolve>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
      <!-- 播放录音弹框 -->
      <playRecording :playRecording="playRecordingShow" @closePlayRecording="showPlayRecording"></playRecording>
    </el-main>
  </el-container>
</template>
<script>
import CallSolve from '@/components/warning/callSolve'
import PrivacyCall from '@/components/public/privacyCall'
import PlayRecording from '@/components/public/playRecording'
import {
  queryCallWarning,
  queryCallDataList,
  queryCallThisMonthEventList,
  queryCallProcessingTimeList
} from '@/api/warning.js'

export default {
  props: ['callSolve', 'privacyCall', 'playRecording'],
  components: {
    callSolve: CallSolve,
    privacyCall: PrivacyCall,
    playRecording: PlayRecording
  },
  data() {
    return {
      callSolveShow: false,
      privacyCallShow: false,
      playRecordingShow: false,
      query: {
        page: 1,
        pageSize: 10
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      tableData: [],
      // 来电总数
      callAllTotal: 0,
      //物业处理次数
      callPropertyTotal: 0,
      //平台处理次数
      callOperateTotal: 0,
      //未接听次数
      callUpPaidTotal: 0,
      // 本月新增
      thisMonthEventTotal: 0,
      color: 'red',
      eventTimeOutTotal: 0,
      eventSeriousTimeOutTotal: 0
    }
  },
  created() {
    this.init()
    this.getCallDataList()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryCallWarning(this.query)
      if (result.success) {
        this.tableData = result.data
        this.callAllTotal = result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 数据统计
    async getCallDataList() {
      let propertyResolver = '物业解决'
      let result1 = await queryCallDataList(propertyResolver, this.query)
      if (result1.success) {
        this.callPropertyTotal = result1.data.total
      }
      let operateResolver = '平台解决'
      let result2 = await queryCallDataList(operateResolver, this.query)
      if (result2.success) {
        this.callOperateTotal = result2.data.total
      }
      let upPaidResolver = '未接听'
      let result3 = await queryCallDataList(upPaidResolver, this.query)
      if (result3.success) {
        this.callUpPaidTotal = result3.data.total
      }
      // 本月新增
      let result4 = await queryCallThisMonthEventList(this.query)
      if (result4.success) {
        this.thisMonthEventTotal = result4.data.total
      }
      // 超时处理
      let processingTime = '1'
      let result7 = await queryCallProcessingTimeList(this.query, processingTime)
      if (result7.success) {
        this.eventTimeOutTotal = result7.data.total
      }
      // 严重超时处理
      let processingSeriousTime = '2'
      let result8 = await queryCallProcessingTimeList(this.query, processingSeriousTime)
      if (result8.success) {
        this.eventSeriousTimeOutTotal = result8.data.total
      }
    },
    showCallSolve() {
      this.callSolveShow = false
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    playRow(index) {
      console.log(index)
      this.playRecordingShow = true
    },
    callbackRow(index) {
      console.log(index)
      this.privacyCallShow = true
    },
    solveRow(index) {
      console.log(index)
      this.callSolveShow = true
    },
    showPlayRecording(index) {
      console.log(index)
      this.playRecordingShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
