<template>
  <el-container>
    <el-header class="header">档案管理>新增接入</el-header>
    <el-main>
      <!-- 精准查询 -->
      <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">楼号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">单元</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <!-- 打开开通弹框 -->
        <addOpen :addOpen="openShow" @closeOpen="showOpen"></addOpen>
        <el-table :data="tableData && tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="floorName" label="房号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.floorName }}号楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                {{ scope.row.roomName }}室
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="ownerName" label="业主姓名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="openMethod" label="开通方式" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.openMethod == 0 ? '自主开通' : '物业开通' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="serviceType" label="服务类型" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="servicePackage" label="服务套餐" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="serviceStatus" label="服务状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.serviceStatus == 1 ? '正常' : '异常' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="equipmentStatus" label="设备状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.serviceStatus == 1 ? '正常' : '异常' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="paymentMethod" label="支付方式" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p v-if="scope.row.paymentMethod == 0" :style="{ color: color }">未支付</p>
              <p v-if="scope.row.paymentMethod != 0">已支付</p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="申请时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.createTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="openRow(scope.$index, tableData)" type="text" size="small">
                开通
              </el-button>
              <el-button @click.native.prevent="callbackRow(scope.$index, tableData)" type="text" size="small">
                回拨
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData && tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
    </el-main>
  </el-container>
</template>
<script>
import AddOpen from '@/components/archives/addOpen'
import PrivacyCall from '@/components/public/privacyCall'
import { queryNewProcess } from '@/api/archives.js'

export default {
  props: ['addOpen', 'privacyCall'],
  components: {
    addOpen: AddOpen,
    privacyCall: PrivacyCall
  },
  data() {
    return {
      openShow: false,
      privacyCallShow: false,
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      query: {
        page: 1,
        pageSize: 10
      },
      tableData: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryNewProcess(this.query)
      if (result.success) {
        this.tableData = result.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    showOpen() {
      this.openShow = false
    },
    showOpen() {
      this.openShow = false
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    openRow(index) {
      this.openShow = true
    },
    callbackRow(index) {
      this.privacyCallShow = true
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
