<template>
  <el-container>
    <el-dialog title="解决" :visible.sync="callSolve" width="30%" @close="close">
      <div class="content">
        <div class="input_item">
          <div class="item_vlaue">处理记录</div>
          <div class="item_key">
            <el-input type="textarea" :rows="4" v-model="form.callSolve" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="callSolve = false">取 消</el-button>
        <el-button type="primary" @click="callSolve = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['callSolve'],
  data () {
    return {
      form: {
        callSolve: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('closeCallSolve')
    }
  }
}
</script>
<style lang="less" scoped>
.item_vlaue {
  padding-bottom: 16px;
}
.item_key {
  display: flex;
  align-items: center;
  .name {
    margin-right: 16px;
  }
  .radio {
    display: flex;
  }
}
</style>
