<template>
  <el-container>
    <el-dialog title="拨打电话" :visible.sync="privacyCall" width="30%" @close="close">
      <div class="content">
        <div class="number">{{record.ownerPhone || record.callTelephone}}</div>
        <!-- <div class="explain">为保证客户隐私，此号为临时虚拟号码，请在5分钟内使用。</div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="privacyCall = false">取 消</el-button>
        <el-button type="success" @click="callPhone">继续拨打</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['privacyCall','record','isCallRecord'],
  data () {
    return {
      form: {
        privacyCall: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('closePrivacyCall')
    },
    callPhone () {
      if(this.isCallRecord){
        window.cp.createCall({ phone:  this.record.callTelephone, callType: 1,wdeviceDetails:this.record }).show()
      }else{
        window.cp.createCall({ phone: this.record.ownerPhone , callType: 1,userInfo:this.record }).show()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  color: #202626;
  text-align: center;
  .number {
    font-size: 40px;
    font-weight: 500;
    padding: 16px 0;
  }
}
</style>
