import { render, staticRenderFns } from "./fileDetails.vue?vue&type=template&id=214c9a82&scoped=true&"
import script from "./fileDetails.vue?vue&type=script&lang=js&"
export * from "./fileDetails.vue?vue&type=script&lang=js&"
import style0 from "./fileDetails.vue?vue&type=style&index=0&id=214c9a82&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "214c9a82",
  null
  
)

export default component.exports