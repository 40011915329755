<template>
  <el-container class="playRecordingBox">
    <el-dialog class="playRecordingBox" title="时间：2022.07.20 09:02:34 - 2022.07.20 09:05:34" :visible.sync="playRecording" width="30%" @close="close" v-el-drag-dialog :modal='false' :close-on-click-modal="false" :modal-append-to-body="false">
      <div class="content">
        播放录音
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
export default {
  props: ['playRecording'],
  directives: {
    elDragDialog
  },
  data () {
    return {
      form: {
        playRecording: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('closePlayRecording')
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  color: #202626;
  text-align: center;
  .number {
    font-size: 40px;
    font-weight: 500;
    padding: 16px 0;
  }
}
</style>
