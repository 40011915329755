<template>
  <el-container>
    <el-dialog title="设置班次" :visible.sync="addShift" width="30%" @close="close">
      <div class="content">
        <div class="input_item">
          <div class="item_vlaue">上班时间</div>
          <div class="item_key">
            <el-time-select class="timeSelect" v-model="value" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间"> </el-time-select>
          </div>
        </div>
        <div class="input_item">
          <div class="item_vlaue">下班时间</div>
          <div class="item_key">
            <el-time-select class="timeSelect" v-model="value" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间"> </el-time-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShift = false">取 消</el-button>
        <el-button type="primary" @click="addShift = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['addShift'],
  data () {
    return {
      value: '',
      form: {
        addShift: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('closeAddShift')
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .timeSelect {
    width: 100%;
  }
}
</style>
