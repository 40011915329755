<template>
  <el-container>
    <el-dialog class="openCall"  :visible.sync="isShow" width="30%" top="80vh" @close="hide" :modal="false" append-to-body :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false">
      <div class="content">
        <div class="number" v-if="wdeviceDetails">{{wdeviceDetails.floorName}}号楼{{wdeviceDetails.unitName}}单元{{wdeviceDetails.roomName}} {{userInfo.ownerName}}</div>
        <div class="c_botBox" v-if="callType == 0">
          <div class="c_b_txt">来电 {{ phone }}</div>
          <div class="c_icon" @click="handOn">
            <img src="../../../assets/img/call_green.svg" />
          </div>
        </div>
        <div class="c_botBox" v-if="callType == 1">
          <div class="c_b_txt">正在呼叫    {{phone}}</div>
          <div class="c_icon" @click="handOff">
            <img src="../../../assets/img/call_red.svg" />
          </div>
        </div>
        <div class="c_botBox" v-if="callType == 2">
          <!-- <div class="c_b_txt">00:00</div> -->
          <div class="c_b_txt">{{timeMin }}:{{ timeSec }}</div>
          <div class="c_icon" @click="handOff">
            <img src="../../../assets/img/call_red.svg" />
          </div>
        </div>
        <div class="c_botBox" v-if="callType == 3">
          <div class="c_b_txt">未接来电 {{phone}}</div>
          <div class="c_icon" @click="handOff">
            <img src="../../../assets/img/call_red.svg" />
          </div>
        </div>        
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props:['phone','callType','wdeviceDetails','userInfo'],
  data () {
    return {
      isShow:false,
      timeSec:'00',
      timeMin:'00',
      timer:null
    }
  },
  watch :{
    'phone'  (n,o) {
      console.log(1111,n,o)
    },
  },
  mounted(){
    if(this.callType == 2){
      this.clc()
    }
  },
  methods: {
    show(){
      this.isShow= true
    },
    hide () {
      this.isShow = false
      this.remove()
    },
    clc(){
      clearInterval(this.timer)
      let countS = 0 
      let countT = 0 
      let numS = 0
      let numT = 0
      this.timer = setInterval(()=>{
        countS++
        if(countS == 10){
          countS = 0
          countT ++
        }
        if(countT == 6) {
          countT = 0
          numS++
        }
        if(numS == 10) {
          numS =0
          numT++
        }
        this.timeSec = countT + '' + countS
        this.timeMin = numT + '' + numS
      },1000)
    },

    handOn(){ //接听
      this.globalCall.handOn()
      this.callType = 2
      this.clc()
      // this.hide()
      this.cpRecord.callStartTime = this.formatDate(new Date()) //记录通话开始时间

    },
    handOff(){  //挂断
      this.hide()
      this.globalCall.handOff()
      this.cpRecord.callEndTime = this.formatDate(new Date()) //记录通话结束时间
      if( this.wdeviceDetails ) {
        window.cp.createDlgSolve({userInfo:this.userInfo,type:2,wdeviceDetails:this.wdeviceDetails}).show() //设备回访解决弹窗
      }else if(this.userInfo){
        window.cp.createDlgSolve({userInfo:this.userInfo,type:3}).show() //呼入记录回访
      }
    },

  }
}
</script>
<style lang="less">
.openCall {
  .el-dialog {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    position: fixed;
    right: 24px;
  }
  .el-dialog__body {
    padding: 0 30px 30px 30px;
  }
  .content {
    color: #202626;
    .number {
      font-size: 32px;
      font-weight: 500;
    }
    .c_botBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .c_b_txt {
        font-size: 18px;
        font-weight: 500;
        opacity: 0.5;
      }
      .c_icon {
        width: 64px;
        height: 64px;
        cursor: pointer;
      }
    }
  }
}
</style>
