<template>
  <el-container>
    <!-- 左侧导航 -->
    <el-aside width="320px" class="house_left">
      <div class="house_tit">房屋管理</div>
      <!-- :default-expand-all="true" -->
      <div class="house_inner">
        <el-tree
          :data="treeList"
          :props="treesProps"
          @node-click="handleNodeClick"
          node-key="id"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span class="treeBtn">
              <el-button type="text" size="mini" @click="() => append(data)"> 编辑 </el-button>
              <el-button type="text" size="mini" @click="() => remove(data)"> 删除 </el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </el-aside>
    <!-- 右侧列表 -->
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ floorAllTotal }}</div>
                <div>总楼栋(栋)</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ roomAllTotal }}</div>
                <div>总户数(户)</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 面包屑导航 -->
      <el-header class="header">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>601号楼</el-breadcrumb-item>
          <el-breadcrumb-item>1单元</el-breadcrumb-item>
        </el-breadcrumb> -->
        <div>
          <el-button type="primary" @click="batchShow = true">批量新增</el-button>
          <el-button type="primary" @click="singleShow = true">单个新增</el-button>
        </div>
      </el-header>
      <!-- 列表 -->
      <div class="list">
        <el-table ref="multipleTable" :data="tableList" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="floorName" label="楼号" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="unitName" label="单元" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="layerName" label="楼层" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="room_name" label="房号" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="buil_area" label="建筑面积(m2)" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="house_type" label="户型" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="append(scope.row)" type="text" size="small"> 编辑 </el-button>
              <el-button @click.native.prevent="remove(scope.row)" type="text" size="small"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <BatchDlg :batchShow="batchShow" @closeBatch="closeBatch" :init="init"></BatchDlg>
    <SingleDlg
      :singleShow="singleShow"
      @closeSingle="closeSingle"
      :init="init"
      :selectedInfo="selectedInfo"
    ></SingleDlg>
    <!-- 编辑楼号、单元、楼层、房号名称 -->
    <el-dialog title="编辑" :visible.sync="editNameShow" width="30%">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="名称">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colseEditName">取 消</el-button>
        <el-button type="primary" @click="saveEditName">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import {
  getHoseTotal,
  getRoomAllList,
  putRoomName,
  deleteRoomName,
  putLayerName,
  deleteLayerName,
  putUnitName,
  deleteUnitName,
  putHouseName,
  deleteHouseName
} from '@/api/property.js'
import BatchDlg from './comp/addAbtch.vue'
import SingleDlg from './comp/addSingle.vue'

export default {
  components: {
    BatchDlg,
    SingleDlg
  },
  data() {
    return {
      treeList: [],
      tree: [],
      batchShow: false,
      singleShow: false,
      treesProps: {
        label: 'name',
        children: 'children'
      },
      tableList: [], //表格数据
      data: [],
      tableData: [],
      multipleSelection: [],
      // 总楼栋
      floorAllTotal: 0,
      // 总房屋
      roomAllTotal: 0,
      query: {
        page: 1,
        pageSize: 10
      },
      selectedInfo: {},
      editNameShow: false,
      formData: {}
    }
  },
  created() {
    // this.getPropertyRoomAllList()
    this.init()
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
  },
  methods: {
    //初始化表格
    async init() {
      let result = await getHoseTotal()
      if (result.success) {
        this.treeList = this.getDt(result.data.floors)
        this.floorAllTotal = Object.keys(result.data.floors).length
      }
      this.getPropertyRoomAllList()
    },
    //递归获取信息
    getDt(list) {
      let tree = []
      const that = this
      list.map((d) => {
        tree.push({
          ...d,
          name: d.room_name
            ? d.room_name + '室'
            : d.floorName
            ? d.floorName + '号楼'
            : d.unitName
            ? d.unitName + '单元'
            : d.layerName
            ? d.layerName + '层'
            : '',
          children: d.children ? that.getDt(d.children) : []
        })
      })
      return tree
    },
    seeRow(index) {
      // console.log(index)
    },
    getCurTable(data) {
      if (Array.isArray(data)) {
        data.map((d) => {
          if (d.children.length > 0) {
            this.getCurTable(d.children)
          } else if (d.room_name) {
            this.tableList.push(d)
          }
        })
      } else if (data.constructor === Object) {
        if (data.layer_id && data.children) {
          //如果是某个room
          this.tableList.push(data)
        }
        if (data.children.length > 0) {
          this.getCurTable(data.children)
        }
      }
      return this.tableList
    },
    handleNodeClick(data) {
      this.tableList = []
      this.selectedInfo = data
      this.getCurTable(data)
    },
    // 总户数
    async getPropertyRoomAllList() {
      let result = await getRoomAllList(this.query)
      if (result.success) {
        this.roomAllTotal = result.data.total
      }
    },
    //关闭新增弹窗
    closeBatch() {
      this.batchShow = false
    },
    //关闭新增弹窗
    closeSingle() {
      this.singleShow = false
    },
    // 编辑
    append(data) {
      this.editNameShow = true
      this.formData = data
      if (data.room_name) {
        // console.log(data.room_name)
        let name = data.name.substring(0, data.name.length - 1)
        this.formData.name = name
      } else if (data.floorName) {
        let name = data.name.substring(0, data.name.length - 2)
        this.formData.name = name
      } else if (data.unitName) {
        let name = data.name.substring(0, data.name.length - 2)
        this.formData.name = name
      } else if (data.layerName) {
        let name = data.name.substring(0, data.name.length - 1)
        this.formData.name = name
      }
    },
    async saveEditName() {
      if (this.formData.room_name) {
        this.formData.room_name = this.formData.name
        let formData = {}
        Object.assign(formData, {
          roomName: this.formData.name
        })
        const res = await putRoomName(this.formData.id, formData)
        if (res.success) {
          this.editNameShow = false
          this.init()
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.success)
        }
      } else if (this.formData.layerName) {
        this.formData.room_name = this.formData.name
        let formData = {}
        Object.assign(formData, {
          layerName: this.formData.name
        })
        const res = await putLayerName(this.formData.id, formData)
        if (res.success) {
          this.editNameShow = false
          this.init()
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.success)
        }
      } else if (this.formData.unitName) {
        this.formData.room_name = this.formData.name
        let formData = {}
        Object.assign(formData, {
          unitName: this.formData.name
        })
        const res = await putUnitName(this.formData.id, formData)
        if (res.success) {
          this.editNameShow = false
          this.init()
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.success)
        }
      } else if (this.formData.floorName) {
        this.formData.room_name = this.formData.name
        let formData = {}
        Object.assign(formData, {
          floorName: this.formData.name
        })
        const res = await putHouseName(this.formData.id, formData)
        if (res.success) {
          this.editNameShow = false
          this.init()
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
        } else {
          this.$message.error(res.success)
        }
      }
    },
    colseEditName() {
      this.editNameShow = false
      this.init()
    },
    // 删除
    remove(data) {
      this.$confirm('删除前请确认是否有关联的内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          if (!data.children || data.children.length == 0) {
            if (data.room_name) {
              const res = await deleteRoomName(data.id)
              if (res.success) {
                this.init()
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              } else {
                this.$message.error('删除失败，请先确认此房屋没有关联任何服务')
              }
            } else if (data.floorName) {
              const res = await deleteHouseName(data.id)
              if (res.success) {
                this.init()
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              }
            } else if (data.unitName) {
              const res = await deleteUnitName(data.id)
              if (res.success) {
                this.init()
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              }
            } else if (data.layerName) {
              const res = await deleteLayerName(data.id)
              if (res.success) {
                this.init()
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              }
            }
          } else {
            this.$message.error('请先删除子级内容')
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-breadcrumb {
    font-size: 16px !important;
    font-weight: 500;
  }
}
.house_left {
  height: calc(100vh - 60px);
  border-right: 1px solid #e4e8e8;
  .house_tit {
    height: 28px;
    line-height: 28px;
    padding: 16px;
    font-weight: 500;
    font-size: 16px;
  }
  .house_inner {
    padding: 0 16px;
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.treeBtn {
  display: none;
}
.custom-tree-node:hover {
  .treeBtn {
    display: block;
  }
}
</style>
