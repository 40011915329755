<template>
  <el-container>
    <!-- 面包屑导航 -->
    <el-header class="header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/fileList' }">业主档案</el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ profileRecord.villageName }}小区{{ profileRecord.floorName }}号楼{{ profileRecord.unitName }}单元{{
            profileRecord.layerName
          }}层{{ profileRecord.roomName }}室
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button type="success" @click="call">拨打固话</el-button>
      </div>
    </el-header>
    <el-main>
      <!-- 基本信息 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">
          基本信息
          <!-- {{ profileRecord }} -->
        </div>
        <el-row class="baseInfo_group">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">业主姓名：</div>
              <div class="grid_key">{{ userNameStr(profileRecord.ownerName) }}先生/女士</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">联系电话：</div>
              <div class="grid_key">
                {{ mobileStr(profileRecord.ownerPhone) }}
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">紧急联系人：</div>
              <div class="grid_key">{{ userNameStr(profileRecord.emergencyContactName) }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">联系电话：</div>
              <div class="grid_key">{{ mobileStr(profileRecord.emergencyContactPhone) }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">居住情况：</div>
              <div class="grid_key">{{ profileRecord.mobile }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">所属地区：</div>
              <div class="grid_key">{{ profileRecord.propertyArea }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">所属小区：</div>
              <div class="grid_key">{{ profileRecord.propertyName }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">详细地址：</div>
              <div class="grid_key">{{ profileRecord.propertyAddress }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 服务信息 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">服务信息</div>
        <el-row class="baseInfo_group">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">服务类型：</div>
              <div class="grid_key">{{ profileRecord.serviceType }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">服务状态：</div>
              <div class="grid_key">{{ profileRecord.serviceStatus == 1 ? '正常' : '异常' }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">服务套餐：</div>
              <div class="grid_key">{{ profileRecord.serviceType }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">开通方式：</div>
              <div class="grid_key">{{ profileRecord.openMethod == 0 ? '自主开通' : '物业开通' }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">接入时间：</div>
              <div class="grid_key">{{ formatDate(profileRecord.accessTime) }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">到期时间：</div>
              <div class="grid_key">{{ formatDate(profileRecord.dueDate) }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 投保信息 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">投保信息</div>
        <el-row class="baseInfo_group">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">保险名称：</div>
              <div class="grid_key">{{ insureData && insureData.insureTitle }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">保险公司：</div>
              <div class="grid_key">{{ insureData && insureData.insureEnterprise }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">保险状态：</div>
              <div class="grid_key">{{ insureData && insureData.insureStatus }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">保险期限：</div>
              <div class="grid_key">{{ insureData && insureData.insureTerm }}年</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">总理赔额：</div>
              <div class="grid_key">{{ insureData && insureData.insureTotal }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">投保日期：</div>
              <div class="grid_key">
                {{ formatDate(insureData && insureData.accessTime) }}
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">截止日期：</div>
              <div class="grid_key">
                {{ formatDate(insureData && insureData.dueDate) }}
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">投保人：</div>
              <div class="grid_key">{{ insureData && insureData.insureName }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <!-- TODOOOOOOO -->
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">电子保单：</div>
              <div class="grid_key">请在手机端查看</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">投保金额：</div>
              <div class="grid_key">{{ insureData && insureData.insureMoney }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 设备状况 -->
      <div class="baseInfo">
        <div class="baseInfo_tit">设备状况</div>
        <el-row class="baseInfo_group">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">{{ phoneList.deviceName }}：{{ phoneList.deviceNumber }}</div>
            </div>
          </el-col>
          <el-col :span="6" v-for="(item, i) in deviceList.rows" :key="i">
            <div class="grid-content bg-purple">
              <div class="grid_vlaue">{{ item.deviceName }}：{{ item.iMEI }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 家庭成员列表 -->
      <div class="family">
        <div class="family_tit">家庭成员列表</div>
        <el-row :gutter="8">
          <el-col :span="4" class="f_grout" v-for="(item, i) in memberList" :key="i">
            <div class="tag">{{ item.ownerRelationship }}</div>
            <div class="grid-content bg-purple">
              <div class="grid_avatar">
                <img v-if="item.gender == '先生'" src="../../assets/img/avatar_sir.png" alt="gender" />
                <img v-if="item.gender == '女士'" src="../../assets/img/avatar_MS.png" alt="gender" />
              </div>
              <div class="grid_text">
                <div class="name">
                  <span>{{ userNameStr(item.name) }}{{ item.gender }}</span>
                  <span>&nbsp;&nbsp;{{ item.age }}岁&nbsp;&nbsp;</span>
                  <span class="gender"><img src="../../assets/img/sir.svg" alt="gender" /></span>
                </div>
                <div class="case">{{ item.chronic_disease }}</div>
                <div class="ability">{{ item.disability_condition }}</div>
              </div>
              <div class="btn">
                <el-button type="danger" size="mini" @click="call">拨打电话</el-button>
                <el-button size="mini" @click="openAuth(item)">查看详情</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
      <!-- 身份验证弹框 -->
      <auth :auth="authShow" @closeAuth="showAuth" :ok="checkNo"></auth>
    </el-main>
  </el-container>
</template>
<script>
import PrivacyCall from '@/components/public/privacyCall'
import Auth from '@/components/archives/auth'
import { mapGetters } from 'vuex'
import { queryInsureByRoomAll, queryMemberById } from '@/api/archives.js'
import { getSmartDeviceList, getSmartPhoneList } from '@/api/device.js'

export default {
  props: ['privacyCall'],
  components: {
    privacyCall: PrivacyCall,
    auth: Auth
  },
  data() {
    return {
      privacyCallShow: false,
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      authShow: false,
      insureData: {},
      memberList: [],
      curMember: '', //当前成员
      query: {
        page: 1,
        pageSize: 10
      },
      phoneList: {},
      deviceList: []
    }
  },
  computed: {
    ...mapGetters(['profileRecord'])
  },
  mounted() {
    this.getInsureInfo()
    this.getMemberList()
    this.getSmartDeviceList()
    this.getSmartPhoneList()
  },
  methods: {
    openAuth(item) {
      this.curMember = item
      this.authShow = true
    },
    //获取投保信息
    async getInsureInfo() {
      let result = await queryInsureByRoomAll(this.profileRecord.roomId, this.query)
      if (result.success) {
        this.insureData = result.data.rows[0]
      }
    },
    //获取家庭成员列表
    async getMemberList() {
      let result = await queryMemberById(this.profileRecord.roomId, this.query)
      if (result.success) {
        this.memberList = result.data.rows
      }
    },
    //验证身份信息
    async checkNo(id) {
      if (this.curMember.card_no == id) {
        this.$store.commit('SET_PRORILERECORD', this.profileRecord)
        this.$router.push({ path: '/FMDetails?id=' + this.curMember.id })
      } else {
        this.$message.error('身份验证失败')
      }
    },
    //获取家庭设备列表
    async getSmartDeviceList() {
      let result = await getSmartDeviceList(this.profileRecord.roomId, this.query)
      if (result.success) {
        this.deviceList = result.data
      }
    },
    //获取家庭电话列表
    async getSmartPhoneList() {
      let result = await getSmartPhoneList(this.profileRecord.roomId)
      if (result.success) {
        this.phoneList = result.data
      }
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    call() {
      this.privacyCallShow = true
    },
    showAuth() {
      this.authShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-breadcrumb {
    font-size: 16px !important;
    font-weight: 500;
  }
}
.baseInfo {
  margin: 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .baseInfo_tit {
    font-weight: 500;
    padding-bottom: 16px;
  }
  .baseInfo_group {
    padding: 8px 0;
    .grid-content {
      padding: 8px 0;
    }
  }
  .grid-content {
    display: flex;
  }
}
.family {
  padding: 0 16px;
  .family_tit {
    font-weight: 500;
    padding-bottom: 16px;
  }
  .f_grout {
    margin-bottom: 8px;
  }
  .tag {
    position: absolute;
    padding: 0 16px;
    line-height: 40px;
    background: #09bebe;
    color: #fff;
  }
  .grid-content {
    padding: 16px;
    background: #ffffff;
    border: 1px solid #c7cfcf;
    text-align: center;
    height: 276px;
    .grid_avatar {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      padding-top: 40px;
    }
    .gender {
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 20px;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      padding: 8px 0;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .case,
    .ability {
      padding: 4px 0;
    }
    .btn {
      display: flex;
      padding-top: 8px;
      justify-content: center;
    }
  }
}
</style>
