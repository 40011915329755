import request from '@/utils/request'

// 省市区三级联动 - 查询全部树
export function proCityAreaFindAll() {
    return request({
        url: '/property/province/proCityAreaFindAll',
        method: 'get',
    })
} 
// 省市区三级联动 - 根据code查询省份
export function getProvinceCode(code) {
    return request({
        url: '/property/province/code/' + code,
        method: 'get',
    })
} 
// 省市区三级联动 - 根据code查询城市
export function getCityCode(code) {
    return request({
        url: '/property/city/code/' + code,
        method: 'get',
    })
} 
// 省市区三级联动 - 根据code查询地区
export function getAreaCode(code) {
    return request({
        url: '/property/area/code/' + code,
        method: 'get',
    })
} 