import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/home/index.vue'
import Login from '../views/login/login.vue'
import Register from '../views/login/register.vue'
import AddArchives from '../views/archives/add.vue'
import Confirmed from '../views/archives/confirmed.vue'
import FileList from '../views/archives/fileList.vue'
import FileDetails from '../views/archives/fileDetails.vue'
import FMDetails from '../views/archives/FMDetails.vue'
import ResidentialQuarters from '../views/property/residentialQuarters.vue'
import House from '../views/property/house.vue'
import Member from '../views/property/member.vue'
import BeOnDuty from '../views/property/beOnDuty.vue'
import Call from '../views/warning/call.vue'
import Equipment from '../views/warning/equipment.vue'
import Incoming from '../views/traffic/incoming.vue'
import Exhale from '../views/traffic/exhale.vue'
import UserDepositPay from '../views/pay/userDepositPay.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' }, // 默认
  // 登录
  { path: '/login', component: Login },
  // 注册
  { path: '/register', component: Register },
  // 首页
  { path: '/index', component: Index },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      { path: '/', redirect: '/fileList' }, // 默认
      // 档案管理
      { path: '/addArchives', component: AddArchives }, // 新增接入
      { path: '/confirmed', component: Confirmed }, // 待确认接入
      { path: '/fileList', component: FileList }, // 档案列表
      { path: '/fileDetails', component: FileDetails }, // 档案详情
      { path: '/FMDetails', component: FMDetails }, // 成员详情
      // 物业管理
      { path: '/residentialQuarters', component: ResidentialQuarters }, // 小区管理
      { path: '/house', component: House }, // 房屋管理
      { path: '/member', component: Member }, // 成员管理
      { path: '/beOnDuty', component: BeOnDuty }, // 值班管理
      // 预警管理
      { path: '/call', component: Call }, // 来电预警
      { path: '/equipment', component: Equipment }, // 设备预警
      // 话务管理
      { path: '/incoming', component: Incoming }, // 呼入记录
      { path: '/exhale', component: Exhale }, // 呼出记录
    ]
  },
  { path: '/userDepositPay', component: UserDepositPay } // 渲染后台返回的支付form表单
]

const router = new VueRouter({
  routes
})

export default router
