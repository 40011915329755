<template>
  <div class="pageBox" :style="pageBox">
    <div class="pageInner">
      <div class="pageImgBg">
        <!-- 头部 -->
        <div class="headeBox">
          <div class="h_inner">
            <div class="h_i_leftBox">
              <div class="h_i_l_map">
                <div class="h_i_l_m_icon">
                  <img src="../../assets/img/map_icon.svg" />
                </div>
                <div class="h_i_l_m_dec">{{ propertyInfo.propertyCity }}</div>
              </div>
              <div>{{ dateFormat(date) }}</div>
            </div>
            <div class="h_i_minBox">
              <div class="h_i_m_tit">{{ propertyInfo.villageName }}智慧安防监控中心</div>
            </div>
            <div class="h_i_rightBox">
              <div class="h_i_r_addBtn" @click="OpenAddUser">
                <div class="h_i_r_a_txt">新增接入</div>
              </div>
              <div class="h_i_r_adminBtn" @click="getManagement">
                <div class="h_i_r_a_txt">管理中心</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 中间内容 -->
        <div class="mainBox">
          <!-- 左侧 -->
          <div class="mainBox_gird mainLeftBox">
            <!-- 基础信息 -->
            <transition name="baseBox">
              <div class="m_gird m_baseBox" v-if="baseBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_icon">
                      <img src="../../assets/img/tit_icon.svg" />
                    </div>
                    <div>
                      <span>{{ propertyInfo.villageName }} - </span>
                      <span>{{ propertyInfo.propertyName }}</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <el-row class="m_content">
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_icon">
                        <img src="../../assets/img/floor_icon.svg" />
                      </div>
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ propertyInfo.generalBuilding }}</div>
                        <div class="m_c_g_t_tit">总楼栋(栋)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_icon">
                        <img src="../../assets/img/room_icon.svg" />
                      </div>
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ propertyInfo.totalHouseholds }}</div>
                        <div class="m_c_g_t_tit">总户数(户)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_icon">
                        <img src="../../assets/img/access_icon.svg" />
                      </div>
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">
                          {{ userProfileSize }}
                        </div>
                        <div class="m_c_g_t_tit">总接入(户)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_icon">
                        <img src="../../assets/img/server_icon.svg" />
                      </div>
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ severFamilySize }}</div>
                        <div class="m_c_g_t_tit">服务人数(人)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_icon">
                        <img src="../../assets/img/addServer_icon.svg" />
                      </div>
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">
                          {{ parseInt(this.userReviewThisMonthTotal) + parseInt(this.userProfileThisMonthTotal) }}
                        </div>
                        <div class="m_c_g_t_tit">本月新增(户)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_icon">
                        <img src="../../assets/img/proportion.svg" />
                      </div>
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ accessRate }}</div>
                        <div class="m_c_g_t_tit">总接入率(%)</div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </transition>
            <!-- 数据状态 -->
            <transition name="dataBox">
              <div class="m_gird m_dataBox" v-if="dataBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_icon">
                      <img src="../../assets/img/tit_icon.svg" />
                    </div>
                    <div>
                      <span>预警状态</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <el-row class="m_content">
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ deviceEventTotal }}</div>
                        <div class="m_c_g_t_tit">设备总报警(次)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ callAllTotal }}</div>
                        <div class="m_c_g_t_tit">来电总预警(次)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ deviceThisMonthEventTotal }}</div>
                        <div class="m_c_g_t_tit">本月新增(次)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ callThisMonthEventTotal }}</div>
                        <div class="m_c_g_t_tit">本月新增(次)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ unresolvedEventTotal }}</div>
                        <div class="m_c_g_t_tit">未处理报警</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ callUpPaidTotal }}</div>
                        <div class="m_c_g_t_tit">未处理来电</div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </transition>
          </div>
          <!-- 中间  默认无警情显示绿色 有警情显示红色-->
          <div class="mainBox_min shieldBg" v-if="eventType == 0">
            <div class="m_m_midImg">
              <img src="../../assets/img/home_main_shield.svg" />
            </div>
          </div>
          <div class="mainBox_min shieldRedBg" v-if="eventType == 1">
            <div class="m_m_midImg">
              <img src="../../assets/img/home_main_shield_red.svg" />
            </div>
          </div>
          <!-- 右侧 -->
          <div class="mainBox_gird">
            <!-- 待回访 -->
            <transition name="returnBox">
              <div class="m_gird m_returnBox" v-if="returnBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_icon">
                      <img src="../../assets/img/tit_icon.svg" />
                    </div>
                    <div class="m_h_tit">
                      <span>待回访</span>
                      <span class="m_h_dec">仅显示最近20条</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <el-row class="m_g_content">
                  <div class="m_c_list" v-for="(items, index) in revisitedData" :key="index">
                    <div class="m_c_l_inner">
                      <div class="m_c_l_left">
                        <div class="m_c_l_l_tit">
                          【{{
                            items.alarmDevice == '智能燃气探测器'
                              ? '燃气'
                              : items.alarmDevice == '智能烟感探测器'
                              ? '烟感'
                              : '其它'
                          }}
                          -
                          {{
                            items.alarmContent == '55AA02015A' || items.alarmContent == '55AA00015A'
                              ? '报警'
                              : items.alarmContent == '55AA02025A' || items.alarmContent == '55AA00025A'
                              ? '低电量报警'
                              : items.alarmContent == '55AA02035A' || items.alarmContent == '55AA00035A'
                              ? '心跳/恢复正常'
                              : items.alarmContent == '55AA02045A' || items.alarmContent == '55AA00045A'
                              ? '拆除'
                              : items.alarmContent == '55AA02055A' || items.alarmContent == '55AA00055A'
                              ? '测试'
                              : items.alarmContent == '55AA02075A' || items.alarmContent == '55AA00075A'
                              ? '故障'
                              : items.alarmContent == '55AA02085A' || items.alarmContent == '55AA00085A'
                              ? '温度报警'
                              : items.alarmContent == '55AA020A5A' || items.alarmContent == '55AA000A5A'
                              ? '心跳'
                              : items.alarmContent == '55AA020D5A' || items.alarmContent == '55AA000D5A'
                              ? '防拆恢复'
                              : '其它'
                          }}】 {{ items.floorName }}号楼{{ items.unitName }}单元{{ items.layerName }}层{{
                            items.roomName
                          }}
                        </div>
                        <div class="m_c_l_l_time">{{ formatDate(items.createTime) }}</div>
                      </div>
                      <div class="m_c_l_right">
                        <div class="m_c_l_r_btn" @click="OpenCallShow(items)">回访</div>
                      </div>
                    </div>
                  </div>
                </el-row>
              </div>
            </transition>
            <!-- 待安装 -->
            <transition name="installBox">
              <div class="m_gird m_installBox" v-if="installBox == true">
                <!-- 标题 -->
                <div class="m_g_heade">
                  <div class="m_h_inner">
                    <div class="m_h_icon">
                      <img src="../../assets/img/tit_icon.svg" />
                    </div>
                    <div class="m_h_tit">
                      <span>待安装</span>
                      <span class="m_h_dec">仅显示最近20条</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <el-row class="m_g_content">
                  <div class="m_c_list" v-for="(items, index) in userReviewData" :key="index">
                    <div class="m_c_l_inner">
                      <div class="m_c_l_left">
                        <div class="m_c_l_l_tit">
                          {{ items.floorName }}号楼{{ items.unitName }}单元{{ items.layerName }}层{{ items.roomName }}室
                        </div>
                        <div class="m_c_l_l_time">{{ formatDate(items.createTime) }}</div>
                      </div>
                      <div class="m_c_l_right">
                        <div class="m_c_l_r_btn m_c_l_r_moreBtn" @click="OpenReviewedShow(items)">查看</div>
                      </div>
                    </div>
                  </div>
                </el-row>
              </div>
            </transition>
          </div>
        </div>
        <!-- 底部 -->
        <div class="footerBox">
          <div class="f_inner">
            <!-- 设备状态 -->
            <transition name="deviceBox">
              <div class="f_gird f_deviceBox" v-if="deviceBox == true">
                <!-- 标题 -->
                <div class="f_g_heade">
                  <div class="f_h_inner">
                    <div class="f_h_icon">
                      <img src="../../assets/img/tit_icon.svg" />
                    </div>
                    <div>
                      <span>设备状态</span>
                    </div>
                  </div>
                </div>
                <el-row class="m_content">
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ propertySmartPhoneTotal }}</div>
                        <div class="m_c_g_t_tit">智能电话(台)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ propertySmartDeviceTotal }}</div>
                        <div class="m_c_g_t_tit">设备总数(台)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ onDeviceTotal }}</div>
                        <div class="m_c_g_t_tit">设备在线(台)</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="m_c_grid">
                      <div class="m_c_g_txt">
                        <div class="m_c_g_t_number">{{ offDeviceTotal }}</div>
                        <div class="m_c_g_t_tit">设备离线(台)</div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </transition>
            <div class="f_gird f_warnInfoBox">
              <!-- 设备报警记录 -->
              <transition name="deviceWarnBox">
                <div class="fw_gird f_deviceWarnBox" v-if="deviceWarnBox == true">
                  <!-- 标题 -->
                  <div class="f_g_heade">
                    <div class="f_h_inner">
                      <div class="f_h_icon">
                        <img src="../../assets/img/tit_icon.svg" />
                      </div>
                      <div class="f_h_tit">
                        <span>设备报警记录</span>
                        <span class="f_h_dec">仅显示最近20条</span>
                      </div>
                    </div>
                  </div>
                  <!-- 内容 -->
                  <el-row class="f_g_content">
                    <div class="f_g_c_list" v-for="(items, index) in wdeviceData" :key="index">
                      <div class="f_g_c_l_inner">
                        <div class="f_g_c_l_left">
                          <div class="f_g_c_l_l_icon">
                            <img src="../../assets/img/warn_abnormal.svg" v-if="items.resolver == null" />
                            <img src="../../assets/img/warn_normal.svg" v-if="items.resolver != null" />
                          </div>
                          <div class="f_g_c_l_l_txt">
                            <div class="f_c_l_l_t_tit">
                              【{{
                                items.alarmDevice == '智能燃气探测器'
                                  ? '燃气'
                                  : items.alarmDevice == '智能烟感探测器'
                                  ? '烟感'
                                  : '其它'
                              }}
                              -
                              {{
                                items.alarmContent == '55AA02015A' || items.alarmContent == '55AA00015A'
                                  ? '报警'
                                  : items.alarmContent == '55AA02025A' || items.alarmContent == '55AA00025A'
                                  ? '低电量报警'
                                  : items.alarmContent == '55AA02035A' || items.alarmContent == '55AA00035A'
                                  ? '心跳/恢复正常'
                                  : items.alarmContent == '55AA02045A' || items.alarmContent == '55AA00045A'
                                  ? '拆除'
                                  : items.alarmContent == '55AA02055A' || items.alarmContent == '55AA00055A'
                                  ? '测试'
                                  : items.alarmContent == '55AA02075A' || items.alarmContent == '55AA00075A'
                                  ? '故障'
                                  : items.alarmContent == '55AA02085A' || items.alarmContent == '55AA00085A'
                                  ? '温度报警'
                                  : items.alarmContent == '55AA020A5A' || items.alarmContent == '55AA000A5A'
                                  ? '心跳'
                                  : items.alarmContent == '55AA020D5A' || items.alarmContent == '55AA000D5A'
                                  ? '防拆恢复'
                                  : '其它'
                              }}】 {{ items.floorName }}号楼{{ items.unitName }}单元{{ items.layerName }}层{{
                                items.roomName
                              }}
                            </div>
                            <div class="f_c_l_l_t_time">{{ formatDate(items.createTime) }}</div>
                          </div>
                        </div>
                        <div class="f_g_c_l_right">
                          <div class="f_g_c_l_r_btn f_g_c_l_r_moreBtn" @click="OpenDeviceWarn(items)">查看</div>
                          <div class="f_g_c_l_r_btn f_g_c_l_r_callBtn" @click="OpenDeviceWarn(items)">回访</div>
                        </div>
                      </div>
                    </div>
                  </el-row>
                </div>
              </transition>
              <transition name="callWarnBox">
                <!-- 呼入/呼出记录 -->
                <div class="fw_gird f_callWarnBox" v-if="callWarnBox == true">
                  <!-- 标题 -->
                  <div class="f_g_heade">
                    <div class="f_h_inner">
                      <div class="f_h_icon">
                        <img src="../../assets/img/tit_icon.svg" />
                      </div>
                      <div class="f_h_i_right">
                        <div class="f_h_i_tab">
                          <span :style="{ opacity: inCallOpacity, cursor: 'pointer' }" @click="gitInCallWarn"
                            >呼入记录</span
                          >
                          <span :style="{ opacity: exCallOpacity, cursor: 'pointer' }" @click="gitExCallWarn"
                            >呼出记录</span
                          >
                        </div>
                        <div>
                          <span class="f_h_dec">仅显示最近20条</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 呼入记录 showCallInfo = false -->
                  <el-row class="f_g_content" v-if="showCallInfo == true">
                    <div class="f_g_c_list" v-for="(items, index) in callData" :key="index">
                      <div class="f_g_c_l_inner">
                        <div class="f_g_c_l_left">
                          <div class="f_g_c_l_l_icon">
                            <img src="../../assets/img/call_connected.svg" v-if="items.resolver == null" />
                            <img src="../../assets/img/call_missed.svg" v-if="items.resolver != null" />
                          </div>
                          <div class="f_g_c_l_l_txt">
                            <div class="f_c_l_l_t_tit">
                              {{ items.floorName }}号楼{{ items.unitName }}单元{{ items.layerName }}层{{
                                items.roomName
                              }}
                              {{ items.userName }}
                            </div>
                            <div class="f_c_l_l_t_time">{{ formatDate(items.createTime) }}</div>
                          </div>
                        </div>
                        <div class="f_g_c_l_right">
                          <div class="f_g_c_l_r_moreBtn" @click="OpenCallWarnShow(items)">查看</div>
                          <div class="f_g_c_l_r_callBtn" @click="OpenCallWarnShow(items)">回访</div>
                        </div>
                      </div>
                    </div>
                  </el-row>
                  <!-- 呼出记录 showCallInfo = true -->
                  <el-row class="f_g_content" v-if="showCallInfo == false">
                    <div class="f_g_c_list">
                      <div class="f_g_c_l_inner">
                        <div class="f_g_c_l_left">
                          <div class="f_g_c_l_l_icon">
                            <img src="../../assets/img/exCall_connected.svg" />
                          </div>
                          <div class="f_g_c_l_l_txt">
                            <div class="f_c_l_l_t_tit">呼出603号楼1单元1102 桑先生</div>
                            <div class="f_c_l_l_t_time">2022-10-10 20:12:34</div>
                          </div>
                        </div>
                        <div class="f_g_c_l_right">
                          <div class="f_g_c_l_r_moreBtn">查看</div>
                          <div class="f_g_c_l_r_callBtn">回访</div>
                        </div>
                      </div>
                    </div>
                    <div class="f_g_c_list">
                      <div class="f_g_c_l_inner">
                        <div class="f_g_c_l_left">
                          <div class="f_g_c_l_l_icon">
                            <img src="../../assets/img/exCall_missed.svg" />
                          </div>
                          <div class="f_g_c_l_l_txt">
                            <div class="f_c_l_l_t_tit">603号楼1单元1102 桑先生</div>
                            <div class="f_c_l_l_t_time">2022-10-10 20:12:34</div>
                          </div>
                        </div>
                        <div class="f_g_c_l_right">
                          <div class="f_g_c_l_r_moreBtn">查看</div>
                          <div class="f_g_c_l_r_callBtn" @click="OpenCallWarnShow()">回访</div>
                        </div>
                      </div>
                    </div>
                    <div class="f_g_c_list">
                      <div class="f_g_c_l_inner">
                        <div class="f_g_c_l_left">
                          <div class="f_g_c_l_l_icon">
                            <img src="../../assets/img/exCall_missed.svg" />
                          </div>
                          <div class="f_g_c_l_l_txt">
                            <div class="f_c_l_l_t_tit">603号楼1单元1102 桑先生</div>
                            <div class="f_c_l_l_t_time">2022-10-10 20:12:34</div>
                          </div>
                        </div>
                        <div class="f_g_c_l_right">
                          <div class="f_g_c_l_r_moreBtn">查看</div>
                          <div class="f_g_c_l_r_callBtn">回访</div>
                        </div>
                      </div>
                    </div>
                  </el-row>
                </div>
              </transition>
            </div>
            <!-- 系统公告 -->
            <transition name="noticeBox">
              <div class="f_gird f_noticeBox" v-if="noticeBox == true">
                <!-- 标题 -->
                <div class="f_g_heade">
                  <div class="f_h_inner">
                    <div class="f_h_icon">
                      <img src="../../assets/img/tit_icon.svg" />
                    </div>
                    <div>
                      <span>系统公告</span>
                    </div>
                  </div>
                </div>
                <!-- 内容 -->
                <el-row class="f_g_content">
                  <div class="f_g_c_tit">{{ noticeInfo.noticeTitle }}</div>
                  <div class="f_g_c_dec" v-html="noticeInfo.noticeContent"></div>
                </el-row>
              </div>
            </transition>
          </div>
        </div>
        <!-- 新增接入 -->
        <addUser :addUser="addUserShow" @closeAddUser="showAddUser" :addUserInfo="addUserInfo"></addUser>
        <!-- 设备报警详情 -->
        <deviceWarn
          :deviceWarn="deviceWarnShow"
          @closeDeviceWarn="showDeviceWarn"
          :wdeviceDetails="wdeviceDetails"
        ></deviceWarn>
        <!-- 解除报警 -->
        <warnSolve :warnSolve="warnSolveShow" @closeWarnSolve="showWarnSolve"></warnSolve>
        <!-- 电话详情 -->
        <callWarn :callWarn="callWarnShow" @closeCallWarn="showCallWarn" :callDetails="callDetails"></callWarn>
        <!-- 待安装详情 -->
        <reviewed :reviewed="reviewedShow" @closeReviewed="showReviewed" :userReviewInfos="userReviewInfos"></reviewed>
        <!-- 拨打电话/来电 -->
        <openCall :openCall="openCallShow" @closeOpenCall="showCall"></openCall>
      </div>
    </div>
    <!-- 报警提示音 -->
    <audio controls="controls" hidden src="../../assets/music/alarm_music.wav" ref="alarmMusic"></audio>
    <!-- 报警音频 -->
    <audio controls="controls" hidden src="../../assets/music/baojingyiyin.mp3" ref="baojingyiyin"></audio>
    <!-- 消息提示音 -->
    <audio controls="controls" hidden src="../../assets/music/clew_tone.wav" ref="clewTone"></audio>
    <!-- 超时提示音 -->
    <audio controls="controls" hidden src="../../assets/music/overtime.wav" ref="overtime"></audio>
  </div>
</template>
<script>
// 新增接入
import AddUser from '@/components/home/addUser'
// 设备报警详情
import DeviceWarn from '@/components/home/deviceWarn'
// 解除报警
import WarnSolve from '@/components/home/warnSolve'
// 电话详情
import CallWarn from '@/components/home/callWarn'
// 待安装详情
import Reviewed from '@/components/home/reviewed'
// 拨打电话
import OpenCall from '@/components/home/openCall'
// 设备报警列表
import {
  queryDeviceWarning,
  queryCallWarning,
  queryRoomIdToProperty,
  queryCallThisMonthEventList,
  queryCallDataList,
  queryThisMonthEventList,
  queryIsNullEventList
} from '@/api/warning.js'
// 居民
import {
  queryProcessIng,
  queryProList,
  queryFamilyList,
  queryNewProcess,
  queryThisMonthUserProfileList,
  queryThisMonthUserReviewList
} from '@/api/archives.js'
// 物业基础信息
import { getAreaInfo, queryNoticeClassList, queryNoticeClassContentList } from '@/api/property.js'
import { getPropertySmartDeviceList, getPropertySmartPhoneList } from '@/api/device.js'
import { getCityCode } from '@/api/public.js'
// 从session中获取token信息
import session from '@/utils/session.js'

// 定义websoket服务
let ws

export default {
  components: {
    AddUser: AddUser,
    DeviceWarn: DeviceWarn,
    WarnSolve: WarnSolve,
    CallWarn: CallWarn,
    Reviewed: Reviewed,
    OpenCall: OpenCall
  },
  data() {
    return {
      date: new Date(),
      // 控制进入页面时动画
      baseBox: false,
      dataBox: false,
      installBox: false,
      returnBox: false,
      deviceBox: false,
      deviceWarnBox: false,
      callWarnBox: false,
      noticeBox: false,
      // 新增接入
      addUserShow: false,
      // 设备报警详情
      deviceWarnShow: false,
      // 呼入记录 true  呼出记录 false
      showCallInfo: true,
      inCallOpacity: '',
      exCallOpacity: '',
      callDetails: {},
      // 解除报警
      warnSolveShow: false,
      // 电话详情
      callWarnShow: false,
      // 待安装详情
      reviewedShow: false,
      // 拨打电话
      openCallShow: false,
      // 首页根据是否有警情显示对应背景
      backgroundImage: '../../assets/img/home_main_img.svg',
      pageBox: {
        background: ''
      },
      // 0 显示正常盾牌 1 显示红色盾牌
      eventType: '',
      // 分页
      query: {
        page: 1,
        pageSize: 20
      },
      // 设备报警记录
      wdeviceData: [],
      wdeviceDetails: {},
      // 电话预警
      callData: [],
      // 待回访列表
      revisitedData: [],
      // 待安装
      userReviewData: [],
      userReviewInfos: {},
      userReviewListTotal: 0,
      // 获取物业信息
      propertyInfo: {},
      // token信息
      userInfo: {},
      // 已安装数量
      userProfileSize: 0,
      // 接入率
      accessRate: 0,
      // 服务人员数量
      severFamilySize: 0,
      // 本月新增 总量
      thisMonthUserProfileToUserReviewTotal: 0,
      // 本月新增 安装成功
      userProfileThisMonthTotal: 0,
      // 本月新增 待安装
      userReviewThisMonthTotal: 0,
      propertyId: '',
      // 消息校验获取的propertyId
      msgPropertyId: '',
      msgInfo: {},
      // 总报警次数
      deviceEventTotal: 0,
      // 本月新增
      deviceThisMonthEventTotal: 0,
      unresolvedEventTotal: 0,
      // 来电总数
      callAllTotal: 0,
      // 本月新增
      callThisMonthEventTotal: 0,
      callUpPaidTotal: 0,
      // 公告内容
      noticeInfo: {},
      // 电话总数
      propertySmartPhoneTotal: 0,
      // 设备总数
      propertySmartDeviceTotal: 0,
      // 设备在线
      onDeviceTotal: 0,
      // 设备离线
      offDeviceTotal: 0,
      // 公告内容
      noticeInfo: {}
    }
  },
  created() {
    this.userInfo = session.getUserInfo()
    this.propertyId = this.userInfo.propertyId
    this.mobile = this.userInfo.mobile
  },
  mounted() {
    // 控制进入页面时动画
    this.getShowBox()
    this.gitInCallWarn()
    this.gitEventInfo()
    this.deviceInit()
    this.callInit()
    this.userReviewInit()
    this.getUserProfileList()
    this.getSeverFamilyList()
    this.propertyInit()
    this.propertyInits()
    this.getThisMonthUserProfileList()
    this.getThisMonthUserReviewList()
    this.getNoticeList() // 获取公告内容
    this.getPropertySmartDeviceList() // 设备总数
    this.getPropertySmartPhoneList() // 智能电话
    //显示当前日期时间
    let _this = this // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date() // 修改数据date
    }, 1000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
    }
  },
  methods: {
    dateFormat(time) {
      var date = new Date(time)
      var year = date.getFullYear()
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      // 拼接
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    },
    propertyInits() {
      this.lockReconnect = false // 避免重连的机制
      this.heartBeatClock = null // websocket的setTimeout函数
      this.try = null // 尝试重连
      let _this = this
      if (typeof WebSocket === 'undefined') {
        console.log('您的浏览器不支持WebSocket')
      } else {
        // 用于接收结果的webcosket 服务
        // let socketUrl2 = 'ws://kwangda.com:8090/propertyWebsocket/' + this.mobile
        let socketUrl2 = 'wss://kwangda.com:8090/propertyWebsocket/' + this.propertyId
        // let socketUrl2 = 'ws://127.0.0.1:8054'
        if (ws != null) {
          ws.close()
          ws = null
        }
        // 开启webSocket服务
        ws = new WebSocket(socketUrl2)
        console.log('正在开启...')
        // 打开事件
        ws.onopen = function () {
          console.log('设备WebSocket已打开')
        }
        // 浏览器手消息，获得从服务端发过来的消息事件
        ws.onmessage = function (msg) {
          // console.log('收到的数据 =dd=== ' + msg.data)
          if (msg.data == '888888') {
            console.log('WebSocket正常')
          } else {
            let msgInfo = JSON.parse(msg.data) // 对收到的数据进行解析
            _this.getPropertyIdInfo(msgInfo)
            _this.deviceInits(msgInfo)
            _this.eventProcessingTime(msgInfo)
          }
        }
        ws.onclose = (e) => {
          console.log('设备WebSocket已关闭')
          clearTimeout(_this.try)
          _this.try = setTimeout(() => {
            reconnectWebSocket()
          }, 5000)
        }
        ws.onerror = (e) => {
          console.log('设备WebSocket异常关闭')
          clearTimeout(_this.try)
          _this.try = setTimeout(() => {
            _this.reconnectWebSocket()
          }, 5000)
        }
        function reconnectWebSocket() {
          console.log('设备重连中...')
          // this.log = '重连中:' + datePattern(new Date(), 'yyyy-MM-dd HH:mm:ss')
          if (_this.lockReconnect) {
            return
          }
          _this.lockReconnect = true
          // 没连接上会一直重连，设置延迟避免请求过多
          _this.heartBeatClock && clearTimeout(this.heartBeatClock)
          _this.heartBeatClock = setTimeout(() => {
            _this.propertyInits()
            _this.lockReconnect = false
          }, 5000)
        }
        //心跳检测 与服务端约定666666为心跳检测
        //  每隔30s后向服务端发送消息 服务返回888888为正常
        var postBackMes30
        if (!postBackMes30) {
          window.postBackMes30 = setInterval(() => {
            //这里放 websocket的传送信息方法
            var jsonData = { propertyId: this.propertyId, message: '666666' }
            // console.log('我是心跳检测', jsonData)
            ws.send(JSON.stringify(jsonData))
          }, 30 * 1000)
        }
      }
    },

    // 根据报警事件获取弹框信息
    async deviceInits(msgInfo) {
      let propertyId = this.userInfo.propertyId
      // console.log("我获取的propertyId", msgInfo.deviceInfo.propertyId);
      // console.log("我的propertyId", propertyId);
      if (msgInfo.deviceInfo.propertyId == propertyId) {
        let result = await queryDeviceWarning(this.query)
        if (result.success) {
          this.wdeviceData = result.data.rows
          this.wdeviceDetails = this.wdeviceData[0]
          let items = this.wdeviceDetails
          // 弹出报警弹框
          this.OpenDeviceWarn(items)
          // console.log('我是有数据的', result)
          // 播放报警提示音
          this.$refs.alarmMusic.currentTime = 0
          this.$refs.alarmMusic.play()
          this.$refs.baojingyiyin.currentTime = 0
          this.$refs.baojingyiyin.play()
        }
        // 更新设备报警记录列表
        this.deviceInit()
      } else {
        console.log('没有我的数据')
      }
    },
    async getPropertyIdInfo(msgInfo) {
      let result = await queryRoomIdToProperty(msgInfo.sysMessageInfo.roomId)
      if ((result.data.id = this.propertyId)) {
        this.msgInfo = msgInfo.sysMessageInfo
        // console.log("我是有数据的===", msgInfo);
        // 更新设备报警记录列表
        this.deviceInit()
        const h = this.$createElement
        this.$notify({
          title: this.msgInfo.msgTit,
          message: this.msgInfo.msgContent,
          position: 'bottom-right',
          duration: 0
        })
        // 关闭报警弹框
        this.deviceWarnShow = false
        // 播放消息提示音
        this.$refs.clewTone.currentTime = 0
        this.$refs.clewTone.play()
      } else {
        console.log('没有我的数据')
      }
    },
    // 接收事件超时提醒
    eventProcessingTime(msgInfo) {
      if (msgInfo.sysPropertyMessage.propertyId == this.propertyId) {
        this.msgInfo = msgInfo.sysPropertyMessage
        // 更新设备报警记录列表
        this.deviceInit()
        const h = this.$createElement
        this.$notify.error({
          title: this.msgInfo.msgTit,
          message: this.msgInfo.msgContent,
          position: 'bottom-left',
          duration: 0
        })
        // 播放超时提示音
        this.$refs.overtime.currentTime = 0
        this.$refs.overtime.play()
      } else {
        console.log('没有我的数据')
      }
    },
    // 新增接入
    async addUserInfo(data) {
      let result = await queryNewProcess(data)
      if (result.success) {
        if (result.success) {
          this.$message.success('新增接入成功')
          this.userReviewInit()
        }
      }
    },
    // 获取物业基本信息
    async propertyInit() {
      let result = await getAreaInfo(this.userInfo.propertyId)
      if (result.success) {
        if (result.success) {
          this.propertyInfo = result.data
          // 城市
          let res = await getCityCode(this.propertyInfo.propertyCity)
          if (res.success) {
            this.propertyInfo.propertyCity = res.data.name
          }
        }
      }
    },
    // 获取已安装用户列表 获得数量
    async getUserProfileList() {
      let result = await queryProList(this.query)
      if (result.success) {
        this.userProfileSize = result.data.total
      }
    },
    // 获取当前物业全部服务人数
    async getSeverFamilyList() {
      let result = await queryFamilyList(this.query)
      if (result.success) {
        this.severFamilySize = result.data.total
      }
    },
    // 本月新增 安装成功
    async getThisMonthUserProfileList() {
      let result = await queryThisMonthUserProfileList(this.query)
      if (result.success) {
        this.userProfileThisMonthTotal = result.data.total
      }
    },
    // 本月新增 待安装
    async getThisMonthUserReviewList() {
      let result = await queryThisMonthUserReviewList(this.query)
      if (result.success) {
        this.userReviewThisMonthTotal = result.data.total
      }
    },
    // 设备预警
    async deviceInit() {
      let result = await queryDeviceWarning(this.query)
      if (result.success) {
        this.wdeviceData = result.data.rows
        this.deviceEventTotal = result.data.total
        // 根据设备信息显示当前设备报警记录
        this.revisitedData = this.wdeviceData.filter((i) => i.recycleTime == null)
        let warnInfo = this.wdeviceData.filter((i) => i.resolver == null)
        if (warnInfo.length > 0) {
          // 有警情 改变主图状态
          let res = 1
          this.gitEventInfo(res)
        } else {
          // 无警情 改变主图状态
          let res = null
          this.gitEventInfo(res)
        }
      }
      // 本月新增
      let result2 = await queryThisMonthEventList(this.query)
      if (result2.success) {
        this.deviceThisMonthEventTotal = result2.data.total
      }
      // 未解决报警
      let result3 = await queryIsNullEventList(this.query)
      if (result3.success) {
        this.unresolvedEventTotal = result3.data.total
      }
    },
    // 请求是否有报警信息
    gitEventInfo(res) {
      if (res == 1) {
        // 有警情
        this.eventType = 1
        this.pageBox.background = 'radial-gradient(circle at 50% 50%, #3F342C, #000000)'
      } else {
        // 无警情
        this.eventType = 0
        this.pageBox.background = 'radial-gradient(circle at 50% 50%, #2c3f36, #000000)'
      }
    },
    // 电话预警
    async callInit() {
      let result = await queryCallWarning(this.query)
      if (result.success) {
        this.callData = result.data.rows
        this.callAllTotal = result.data.total
      }
      // 本月新增
      let result2 = await queryCallThisMonthEventList(this.query)
      if (result2.success) {
        this.callThisMonthEventTotal = result2.data.total
      }
      // 未接听
      let upPaidResolver = '未接听'
      let result3 = await queryCallDataList(upPaidResolver, this.query)
      if (result3.success) {
        this.callUpPaidTotal = result3.data.total
      }
    },
    // 待安装
    async userReviewInit() {
      let result = await queryProcessIng(this.query)
      if (result.success) {
        this.userReviewData = result.data.rows
        this.userReviewListTotal = result.data.total
      }
    },
    // 根据物业查询所有智能设备列表
    async getPropertySmartDeviceList() {
      const res = await getPropertySmartDeviceList(this.query)
      if (res.success) {
        this.propertySmartDeviceTotal = res.data.total
        let onDevice = res.data.rows.filter((i) => i.event_type == 1)
        this.onDeviceTotal = onDevice.length
        let offDevice = res.data.rows.filter((i) => i.event_type == 0)
        this.offDeviceTotal = offDevice.length
      }
    },
    // 根据物业查询所有智能设备列表
    async getPropertySmartPhoneList() {
      const res = await getPropertySmartPhoneList(this.query)
      if (res.success) {
        this.propertySmartPhoneTotal = res.data.total
      }
    },
    // 控制进入页面时动画
    getShowBox() {
      this.baseBox = !this.baseBox
      this.dataBox = !this.dataBox
      this.installBox = !this.installBox
      this.returnBox = !this.returnBox
      this.deviceBox = !this.deviceBox
      this.deviceWarnBox = !this.deviceWarnBox
      this.callWarnBox = !this.callWarnBox
      this.noticeBox = !this.noticeBox
    },
    // 新增接入
    OpenAddUser() {
      this.addUserShow = true
    },
    showAddUser() {
      this.addUserShow = false
    },
    // 跳转值管理中心
    getManagement() {
      let openNew = this.$router.resolve({ path: '/home' })
      window.open(openNew.href, '_blank')
    },
    // 设备报警详情
    OpenDeviceWarn(items) {
      this.deviceWarnShow = true
      this.wdeviceDetails = items
    },
    showDeviceWarn() {
      this.deviceWarnShow = false
    },
    // 呼入呼出切换请求
    gitInCallWarn() {
      this.inCallOpacity = 1
      this.exCallOpacity = 0.5
      this.showCallInfo = true
    },
    gitExCallWarn() {
      this.inCallOpacity = 0.5
      this.exCallOpacity = 1
      this.showCallInfo = false
    },
    // 解除报警
    OpenWarnSolve() {
      this.warnSolveShow = true
    },
    showWarnSolve() {
      this.warnSolveShow = false
    },
    // 电话详情
    OpenCallWarnShow(items) {
      this.callWarnShow = true
      this.callDetails = items
    },
    showCallWarn() {
      this.callWarnShow = false
    },
    // 待安装详情
    OpenReviewedShow(items) {
      this.reviewedShow = true
      this.userReviewInfos = items
    },
    showReviewed() {
      this.reviewedShow = false
    },
    // 拨打电话
    OpenCallShow(items) {
      // this.openCallShow = true
      this.deviceWarnShow = true
      this.wdeviceDetails = items
    },
    showCall() {
      this.openCallShow = false
    },
    async getNoticeList() {
      const res = await queryNoticeClassList()
      const obj = res.data.find((d) => d.className == '系统公告')
      if (res.success) {
        const ress = await queryNoticeClassContentList(obj.id, this.query)
        if (ress.success) {
          this.noticeInfo = ress.data.rows[0]
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.pageBox {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  // background: radial-gradient(circle at 50% 50%, #2c3f36, #000000);
}
.pageInner {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/home_bg_grid.svg');
  background-size: 100%;
}
.pageImgBg {
  width: 100%;
  height: 100%;
}
.headeBox {
  width: 100%;
  background-image: url(../../assets/img/head_bg.svg);
  background-size: 100% 100%;
  .h_inner {
    height: 76px;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .h_i_leftBox {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      width: 20%;
      .h_i_l_map {
        display: flex;
        align-items: center;
        margin-right: 24px;
        .h_i_l_m_icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
    .h_i_m_tit {
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 12px;
    }
    .h_i_rightBox {
      width: 20%;
      display: flex;
      justify-content: space-between;
      .h_i_r_a_txt {
        transform: skew(42deg);
        cursor: pointer;
      }
      .h_i_r_addBtn,
      .h_i_r_adminBtn {
        width: 46%;
        height: 32px;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 32px;
        transform: skew(-42deg);
        -webkit-transform: skew(-42deg);
        -moz-transform: skew(-42deg);
        -o-transform: skew(-42deg);
        -ms-transform: skew(-42deg);
        background-color: #09be64;
      }
      .h_i_r_adminBtn {
        background-color: #078b8b;
        margin-right: 24px;
      }
      .h_i_r_adminBtn:hover {
        background-color: #056767;
      }
      .h_i_r_addBtn:hover {
        background-color: #078b49;
      }
    }
  }
}
.mainBox {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  height: calc(100vh - 382px);
}
.m_baseBox,
.m_dataBox,
.m_returnBox,
.m_installBox {
  height: 50%;
  overflow: auto;
}
.shieldBg {
  background-image: url('../../assets/img/home_main_img.svg');
}
.shieldRedBg {
  background-image: url('../../assets/img/home_main_redImg.svg');
}
.mainBox_min {
  width: calc(100vw - 816px);
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .m_m_midImg {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  img {
    animation: jumpBoxHandler 2s infinite;
  }
  @keyframes jumpBoxHandler {
    0% {
      transform: translate(0px, 0px); /*开始位置*/
    }
    50% {
      transform: translate(0, 15px); /* 可配置跳动方向 */
    }
    100% {
      transform: translate(0px, 0px); /*结束位置*/
    }
  }
}
.mainBox_gird {
  width: 384px;
  .m_gird {
    background: rgba(158, 171, 171, 0.05);
    margin-bottom: 16px;
    .m_g_heade {
      display: flex;
      align-items: center;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      padding: 0 8px;
      background-image: linear-gradient(
        90deg,
        rgba(151, 151, 169, 0) 4%,
        rgba(151, 151, 169, 0.1) 16%,
        rgba(94, 122, 122, 0) 80%
      );
      .m_h_inner {
        display: flex;
        align-items: center;
        height: 32px;
        width: 100%;
        background-image: linear-gradient(
          90deg,
          rgba(151, 151, 169, 0) 4%,
          rgba(151, 151, 169, 0.2) 34%,
          rgba(94, 122, 122, 0) 60%
        );
        .m_h_icon {
          width: 22px;
          height: 22px;
          margin-right: 8px;
        }
        .m_h_tit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .m_h_dec {
          font-size: 14px;
          font-weight: 400;
          opacity: 0.5;
        }
      }
    }
    .m_content {
      padding: 16px;
      .m_c_grid {
        display: flex;
        align-items: center;
        margin: 15px 0;
        .m_c_g_icon {
          width: 48px;
          height: 48px;
          margin-right: 16px;
        }
        .m_c_g_t_number {
          font-size: 24px;
          font-weight: 500;
        }
        .m_c_g_t_tit {
          font-size: 14px;
          opacity: 0.5;
        }
      }
    }
    .m_g_content {
      .m_c_list {
        border-bottom: 1px solid rgba(123, 123, 141, 0.1);
        .m_c_l_inner {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .m_c_l_l_time {
            font-size: 12px;
            opacity: 0.5;
            padding-top: 4px;
          }
          .m_c_l_r_btn {
            width: 54px;
            padding: 4px 0;
            background: #09be64;
            border-radius: 50px;
            text-align: center;
            cursor: pointer;
          }
          .m_c_l_r_moreBtn {
            background: #333d3d;
          }
        }
      }
    }
  }
}
.f_deviceBox,
.f_deviceWarnBox,
.f_callWarnBox,
.f_noticeBox {
  height: 257px;
  overflow: auto;
}
.footerBox {
  position: fixed;
  width: 100%;
  bottom: 16px;
  .f_inner {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
  }
  .f_deviceBox,
  .f_noticeBox {
    width: 384px;
  }
  .m_content {
    padding: 16px;
    .m_c_grid {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .m_c_g_icon {
        width: 48px;
        height: 48px;
        margin-right: 16px;
      }
      .m_c_g_t_number {
        font-size: 24px;
        font-weight: 500;
      }
      .m_c_g_t_tit {
        font-size: 14px;
        opacity: 0.5;
      }
    }
  }
  .f_warnInfoBox {
    width: calc(100vw - 816px);
    display: flex;
    justify-content: space-between;
    background: none !important;
    .fw_gird {
      width: 100%;
      background: rgba(158, 171, 171, 0.05);
    }
    .f_deviceWarnBox {
      margin-right: 16px;
    }
    .f_h_i_tab {
      span {
        padding-right: 24px;
      }
    }
  }
  .f_gird {
    background: rgba(158, 171, 171, 0.05);
    .f_g_heade {
      display: flex;
      align-items: center;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      padding: 0 8px;
      background-image: linear-gradient(
        90deg,
        rgba(151, 151, 169, 0) 4%,
        rgba(151, 151, 169, 0.1) 16%,
        rgba(94, 122, 122, 0) 80%
      );
      .f_h_inner {
        display: flex;
        align-items: center;
        height: 32px;
        width: 100%;
        background-image: linear-gradient(
          90deg,
          rgba(151, 151, 169, 0) 4%,
          rgba(151, 151, 169, 0.2) 34%,
          rgba(94, 122, 122, 0) 60%
        );
        .f_h_icon {
          width: 22px;
          height: 22px;
          margin-right: 8px;
        }
        .f_h_tit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .f_h_dec {
          font-size: 14px;
          font-weight: 400;
          opacity: 0.5;
        }
        .f_h_i_right {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    .f_g_content {
      padding: 0 16px;
      .f_g_c_list {
        .f_g_c_l_inner {
          padding: 16px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgba(123, 123, 141, 0.1);
          .f_g_c_l_left {
            display: flex;
            align-items: center;
            .f_g_c_l_l_icon {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            .f_c_l_l_t_time {
              font-size: 12px;
              opacity: 0.5;
              padding-top: 4px;
            }
          }
          .f_g_c_l_right {
            display: flex;
            align-items: center;
            .f_g_c_l_r_callBtn {
              width: 54px;
              padding: 4px 0;
              text-align: center;
              background: #09be64;
              border-radius: 50px;
              cursor: pointer;
            }
            .f_g_c_l_r_solveBtn {
              width: 54px;
              padding: 4px 0;
              text-align: center;
              background: #09bebe;
              border-radius: 50px;
              margin-left: 8px;
              cursor: pointer;
            }
            .f_g_c_l_r_moreBtn {
              width: 54px;
              padding: 4px 0;
              text-align: center;
              background: #333d3d;
              border-radius: 50px;
              margin-right: 8px;
              cursor: pointer;
            }
          }
        }
      }
      .f_g_c_tit {
        padding: 16px 0;
      }
      .f_g_c_dec {
        opacity: 0.5;
      }
    }
  }
}
/**
控制元素展开动画
*/
.baseBox-enter-active,
.baseBox-leave-active,
.returnBox-enter-active,
.returnBox-leave-active,
.deviceBox-enter-active,
.deviceBox-leave-active {
  transition: opacity 0.5s;
  transition: transform 0.5s;
}
.baseBox-enter,
.baseBox-leave-to,
.dataBox-enter,
.dataBox-leave-to {
  transform: translateX(-384px);
  opacity: 0;
}
.dataBox-enter-active,
.dataBox-leave-active,
.installBox-enter-active,
.installBox-leave-active,
.deviceWarnBox-enter-active,
.deviceWarnBox-leave-active {
  transition: opacity 0.7s;
  transition: transform 0.7s;
}
.returnBox-enter,
.returnBox-leave-to,
.installBox-enter,
.installBox-leave-to {
  transform: translateX(384px);
  opacity: 0;
}
.deviceBox-enter,
.deviceBox-leave-to,
.deviceWarnBox-enter,
.deviceWarnBox-leave-to,
.callWarnBox-enter,
.callWarnBox-leave-to,
.noticeBox-enter,
.noticeBox-leave-to {
  transform: translateY(384px);
  opacity: 0;
}
.callWarnBox-enter-active,
.callWarnBox-leave-active {
  transition: opacity 0.9s;
  transition: transform 0.9s;
}
.noticeBox-enter-active,
.noticeBox-leave-active {
  transition: opacity 1s;
  transition: transform 1s;
}
</style>
