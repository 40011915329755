<template>
  <el-container>
    <el-header class="header">设备预警</el-header>
    <el-main>
      <!-- 数据统计 -->
      <div class="listData">
        <el-row :gutter="8">
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ deviceEventTotal }}</div>
                <div>报警总次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9f2fb">
              <div class="grid_inner">
                <div class="data_number">{{ thisMonthEventTotal }}</div>
                <div>本月新增</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbf2">
              <div class="grid_inner">
                <div class="data_number">{{ propertyEventTotal }}</div>
                <div>物业处理次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9f2fb">
              <div class="grid_inner">
                <div class="data_number">{{ operateEventTotal }}</div>
                <div>平台处理次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #e9fbfb">
              <div class="grid_inner">
                <div class="data_number">{{ userEventTotal }}</div>
                <div>自主解决次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ reportEventTotal }}</div>
                <div>设备报修次数</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ unresolvedEventTotal }}</div>
                <div>未解决报警</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ eventTimeOutTotal }}</div>
                <div>超时处理</div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple" style="background: #fbe9e9">
              <div class="grid_inner">
                <div class="data_number">{{ eventSeriousTimeOutTotal }}</div>
                <div>严重超时处理</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 精准查询 -->
      <!-- <div class="searchBox">
        <div class="searchTit">精准查询</div>
        <div class="key_group">
          <div class="key_group_item">
            <div class="key_tit">物业公司</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">所属地区</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">楼号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">单元</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="key_group_item">
            <div class="key_tit">房号</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <el-button type="primary">查询</el-button>
        </div>
      </div> -->
      <!-- 列表 -->
      <div class="list">
        <!-- 打开报修弹框 -->
        <equipmentRepair
          :equipmentRepair="equipmentRepairShow"
          @closeEquipmentRepair="showEquipmentRepair"
          :getRepair="getRepair"
        ></equipmentRepair>
        <!-- 打开解决弹框 -->
        <equipmentSolve
          :equipmentSolve="equipmentSolveShow"
          @closeEquipmentSolve="showEquipmentSolve"
          :getSolve="getSolve"
        ></equipmentSolve>
        <el-table :data="tableData.rows" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="roomName" label="房号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.floorName }}楼 {{ scope.row.unitName }}单元 {{ scope.row.layerName }}层
                {{ scope.row.roomName }}室
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="ownerName" label="用户姓名" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>{{ userNameStr(scope.row.ownerName) }}先生/女士</p>
            </template>
          </el-table-column>
          <el-table-column prop="alarmDevice" label="告警设备" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="alarmTime" label="告警时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.alarmTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="alarmContent" label="告警内容" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{
                  scope.row.alarmContent == '55AA02015A' || scope.row.alarmContent == '55AA00015A'
                    ? '报警'
                    : scope.row.alarmContent == '55AA02025A' || scope.row.alarmContent == '55AA00025A'
                    ? '低电量报警'
                    : scope.row.alarmContent == '55AA02035A' || scope.row.alarmContent == '55AA00035A'
                    ? '心跳/恢复正常'
                    : scope.row.alarmContent == '55AA02045A' || scope.row.alarmContent == '55AA00045A'
                    ? '拆除'
                    : scope.row.alarmContent == '55AA02055A' || scope.row.alarmContent == '55AA00055A'
                    ? '测试'
                    : scope.row.alarmContent == '55AA02075A' || scope.row.alarmContent == '55AA00075A'
                    ? '故障'
                    : scope.row.alarmContent == '55AA02085A' || scope.row.alarmContent == '55AA00085A'
                    ? '温度报警'
                    : scope.row.alarmContent == '55AA020A5A' || scope.row.alarmContent == '55AA000A5A'
                    ? '心跳'
                    : scope.row.alarmContent == '55AA020D5A' || scope.row.alarmContent == '55AA000D5A'
                    ? '防拆恢复'
                    : '其它'
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="recycleTime" label="回电时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.recycleTime) }}
              </p>
            </template></el-table-column
          >
          <!-- <el-table-column prop="" label="通话记录" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-button @click.native.prevent="playRow(scope.$index, tableData)" type="text" size="small">
                播放
              </el-button>
            </template>
          </el-table-column> -->
          <el-table-column prop="repair" label="是否报修" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.repair == null ? '-' : '已报修' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="reason" label="报修原因" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.reason != null ? scope.row.reason : '-' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="processingStatus" label="处理状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.processingStatus == null ? '-' : '已处理' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="processingRecord" label="处理记录" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.processingRecord != null ? scope.row.processingRecord : '-' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="processingTime" label="处理时长" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p v-if="scope.row.processingTime == null || scope.row.processingTime == ''">正常</p>
              <p v-if="scope.row.processingTime == 1" :style="{ color: color }">超时</p>
              <p v-if="scope.row.processingTime == 2" :style="{ color: color }">严重超时</p>
            </template>
          </el-table-column>
          <el-table-column prop="resolver" label="解决方" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click.native.prevent="callbackRow(scope.row)" type="text" size="small"> 回拨 </el-button>
              <el-button
                v-if="scope.row.resolver == '未解决' || scope.row.resolver == null"
                @click.native.prevent="equipmentRepairRow(scope.row)"
                type="text"
                size="small"
              >
                报修
              </el-button>
              <el-button
                v-if="scope.row.resolver == '未解决' || scope.row.resolver == null"
                @click.native.prevent="equipmentSolveRow(scope.row)"
                type="text"
                size="small"
              >
                解决
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" :record="curRecord" @closePrivacyCall="showPrivacyCall"></privacyCall>
    </el-main>
  </el-container>
</template>
<script>
import equipmentRepair from '@/components/warning/equipmentRepair'
import equipmentSolve from '@/components/warning/equipmentSolve'
import PrivacyCall from '@/components/public/privacyCall'
import { queryDeviceWarningPut } from '@/api/warning.js'
import {
  queryDeviceWarning,
  queryDeviceDataList,
  queryRepairDataList,
  queryIsNullEventList,
  queryThisMonthEventList,
  queryDeviceProcessingTimeList
} from '@/api/warning.js'

export default {
  props: ['equipmentRepair', 'equipmentSolve', 'privacyCall'],
  components: {
    equipmentRepair: equipmentRepair,
    equipmentSolve: equipmentSolve,
    privacyCall: PrivacyCall
  },
  data() {
    return {
      equipmentRepairShow: false,
      equipmentSolveShow: false,
      privacyCallShow: false,
      query: {
        page: 1,
        pageSize: 10
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      value: '',
      tableData: [],
      // 报警次数
      deviceEventTotal: 0,
      // 物业处理次数
      propertyEventTotal: 0,
      // 平台处理次数
      operateEventTotal: 0,
      // 用户处理次数
      userEventTotal: 0,
      // 报修次数
      reportEventTotal: 0,
      // 未解决报警
      unresolvedEventTotal: 0,
      // 本月新增
      thisMonthEventTotal: 0,
      color: 'red',
      eventTimeOutTotal: 0,
      eventSeriousTimeOutTotal: 0,
      // 报修
      eventRepairInfo: {},
      // 解决
      eventSolveInfo: {},
      curRecord: '' //当前记录
    }
  },
  created() {
    this.init()
    this.getDeviceDataList()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryDeviceWarning(this.query)
      if (result.success) {
        this.tableData = result.data
        this.deviceEventTotal += result.data.total
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    // 报修
    async getRepair(data) {
      let result = await queryDeviceWarningPut(this.eventRepairInfo.id, data)
      if (result.success) {
        this.$message.success('设备报修成功')
        this.equipmentRepairShow = false
        this.init()
      } else {
        this.$message.success(result.message)
      }
    },
    // 解决
    async getSolve(data) {
      let result = await queryDeviceWarningPut(this.eventSolveInfo.id, data)
      if (result.success) {
        this.$message.success('报警解除成功')
        this.equipmentSolveShow = false
        this.init()
      } else {
        this.$message.success(result.message)
      }
    },
    // 数据统计
    async getDeviceDataList() {
      let propertyResolver = '物业解决'
      let result1 = await queryDeviceDataList(propertyResolver, this.query)
      if (result1.success) {
        this.propertyEventTotal = result1.data.total
      }
      let operateResolver = '平台解决'
      let result2 = await queryDeviceDataList(operateResolver, this.query)
      if (result2.success) {
        this.operateEventTotal = result2.data.total
      }
      let userResolver = '自主解决'
      let result3 = await queryDeviceDataList(userResolver, this.query)
      if (result3.success) {
        this.userEventTotal = result3.data.total
      }
      // 报修次数
      let repair = '1'
      let result4 = await queryRepairDataList(repair, this.query)
      if (result4.success) {
        this.reportEventTotal = result4.data.total
      }
      // 未解决报警
      let result5 = await queryIsNullEventList(this.query)
      if (result5.success) {
        this.unresolvedEventTotal = result5.data.total
      }
      // 本月新增
      let result6 = await queryThisMonthEventList(this.query)
      if (result6.success) {
        this.thisMonthEventTotal = result6.data.total
      }
      // 超时处理
      let processingTime = '1'
      let result7 = await queryDeviceProcessingTimeList(this.query, processingTime)
      if (result7.success) {
        this.eventTimeOutTotal = result7.data.total
      }
      // 严重超时处理
      let processingSeriousTime = '2'
      let result8 = await queryDeviceProcessingTimeList(this.query, processingSeriousTime)
      if (result8.success) {
        this.eventSeriousTimeOutTotal = result8.data.total
      }
    },
    showEquipmentRepair() {
      this.equipmentRepairShow = false
    },
    showEquipmentSolve() {
      this.equipmentSolveShow = false
    },
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    playRow(index) {
      // console.log(index)
    },
    callbackRow(record) {
      this.curRecord = record
      this.privacyCallShow = true
    },
    equipmentSolveRow(row) {
      this.equipmentSolveShow = true
      this.eventSolveInfo = row
    },
    equipmentRepairRow(row) {
      this.equipmentRepairShow = true
      this.eventRepairInfo = row
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
}
.listData {
  padding: 16px;
  .grid-content {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    .grid_inner {
      text-align: center;
      .data_number {
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 8px;
      }
    }
  }
}
.searchBox {
  margin: 0 16px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c7cfcf;
  .searchTit {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  }
  .key_group {
    display: flex;
    .key_group_item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .key_tit {
        margin-right: 16px;
      }
    }
  }
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
