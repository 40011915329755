<template>
  <el-container>
    <el-dialog title="查看合同" :visible.sync="lookContract" width="70%" @close="close">
      <div class="content">
        <pdf :src="pdfSrc"></pdf>
        <!-- <pdf src="../../assets/ceshi.pdf"></pdf> -->

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="lookContract = false">取 消</el-button>
        <el-button type="primary" @click="lookContract = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import pdf from 'vue-pdf'
// import pdfFile  from '../../assets/ceshi.pdf'

export default {
  props: ['lookContract','pdfSrc'],
  components: {
    pdf
  },  
  data () {
    return {
      // pdfFile
    }
  },
  methods: {
    close () {
      this.$emit('closeLookContract')
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
}
</style>
