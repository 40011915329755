<template>
  <el-container>
    <el-dialog title="解约" :visible.sync="rescind" width="30%" @close="close">
      <div class="content">
        <div class="input_item">
          <div class="item_vlaue">解约原因</div>
          <div class="item_key">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea">
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rescind = false">取 消</el-button>
        <el-button type="primary" @click="rescind = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['rescind'],
  data () {
    return {
      textarea: ''
    }
  },
  methods: {
    close () {
      this.$emit('closeRescind')
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
}
</style>
