<template>
  <el-container>
    <el-dialog title="单个新增" :visible="singleShow" width="30%" @close="close">
      <section>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item :label="label" prop="chooseValue">
            <el-input v-model="ruleForm.chooseValue" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </section>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="besure">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
  <script>
import { addRoomName, addLayerName, addUnitName, addHouseName } from '@/api/property.js'

export default {
  props: ['singleShow', 'init', 'selectedInfo'],
  data() {
    return {
      chooseLabel: ['楼号', '单元', '层', '房号'],
      ruleForm: {
        chooseType: '',
        chooseValue: ''
      },
      rules: {
        chooseType: [{ required: true, message: '请输入', trigger: 'blur' }],
        chooseValue: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      params: {},
      parentId: '',
      label: '楼号'
    }
  },
  watch: {
    selectedInfo(n, o) {
      if (n.floorName && n.propertyId && !n.room_name) {
        //选择楼号 -新增单元
        this.parentId = n.id
        this.label = '单元'
      } else if (n.unitName && !n.room_name) {
        //选择单元 - 新增楼层
        this.parentId = n.id
        this.label = '楼层'
      } else if (n.layerName && !n.room_name) {
        //选择楼层 - 新增房号
        this.parentId = n.id
        this.label = '房号'
      } else if (n.room_name) {
        //新增房号
        this.parentId = n.unitId
        this.label = '房号'
      }
    }
  },
  methods: {
    //楼号
    async addNum1() {
      let data = {
        floorName: this.ruleForm.chooseValue
      }
      let result = await addHouseName(data)
      if (result.success) {
        this.$message.success('操作成功')
        this.init()
        this.close()
        this.resetForm('ruleForm')
      }
    },
    async addNum2() {
      let data = {
        floorId: this.parentId,
        unitName: this.ruleForm.chooseValue
      }
      let result = await addUnitName(data)
      if (result.success) {
        this.$message.success('操作成功')
        this.init()
        this.close()
        this.resetForm('ruleForm')
      }
    },
    async addNum3() {
      let data = {
        unitId: this.parentId,
        layerName: this.ruleForm.chooseValue
      }
      let result = await addLayerName(data)
      if (result.success) {
        this.$message.success('操作成功')
        this.init()
        this.close()
        this.resetForm('ruleForm')
      }
    },
    async addNum4() {
      let data = {
        layerId: this.parentId,
        roomName: this.ruleForm.chooseValue
      }
      let result = await addRoomName(data)
      if (result.success) {
        this.$message.success('操作成功')
        this.init()
        this.close()
        this.resetForm('ruleForm')
      }
    },
    besure() {
      let that = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.ruleForm.chooseType == 0) {
            if (that.label == '楼号') {
              this.addNum1()
            } else if (that.label == '单元') {
              this.addNum2()
            }
            if (that.label == '楼层') {
              this.addNum3()
            }
            if (that.label == '房号') {
              this.addNum4()
            }
          }
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    close() {
      this.label = '楼号'
      this.$emit('closeSingle')
    }
  }
}
</script>
  <style lang="less" scoped>
.content {
  color: #202626;
  text-align: center;
  .number {
    font-size: 40px;
    font-weight: 500;
    padding: 16px 0;
  }
}
</style>
  