<template>
  <el-container>
    <el-dialog
      title="解除报警"
      v-el-drag-dialog
      :visible.sync="isShow"
      width="20%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="input_item" :model="solveForm" ref="solveForm">
          <div class="item_vlaue">处理结果</div>
          <div class="item_key">
            <div class="item_key">
              <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入简单描述处理结果"
                v-model="solveForm.processingRecord"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="beSure()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
import {postSoloveProblem,postSoloveProblemBack} from '@/api/call.js'

export default {
  props: ['userInfo','type','wdeviceDetails'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      isShow:true,
      solveForm: {
        //解除警报
        processingRecord: '',
        processingStatus: '1',
        //解决方
        resolver: '物业解决',
      }
    }
  },
  methods: {
    cancel () {
      if(this.type == 1) {
        this.getWarnSolveInfo()      
      }else if( this.type == 2 ){
        this.postDeviceBack()      
      }else {
        this.postRecordBack()
      }
    },
    beSure(){
      if(this.type == 1) {
        this.getWarnSolveInfo()      
      }else if( this.type == 2 ){
        this.postDeviceBack()  
      }else{
        this.postRecordBack()      
      }      
    },
    show(){
      this.isShow = true
    },
    hide(){
      this.isShow = false
      this.remove()
      window.cp.createCallDt().hide()
    },    
    close() {
      this.$emit('closeWarnSolve')
    },
    async getWarnSolveInfo () {
      let roomId = this.userInfo.roomId
      let param = {
        userName:this.userInfo.name,
        roomId:this.userInfo.roomId,
        ...this.solveForm,
        ...this.cpRecord
      }      
      this.hide()
      let result = await postSoloveProblem(param)
      if(result.success) {

      }
    },
    async postDeviceBack () {
      let roomId = this.userInfo.roomId
      let param = {
        propertyId : this.wdeviceDetails.propertyId,
        deviceEventId : this.wdeviceDetails.id,
        roomId:this.wdeviceDetails.roomId,
        userName:this.wdeviceDetails.ownerName,
        ...this.solveForm,
        ...this.cpRecord
      }      
      this.hide()
      let result = await postSoloveProblemBack(param)
      if(result.success) {

      }
    },  
    async postRecordBack () {
      let roomId = this.userInfo.roomId
      let param = {
        propertyId : this.userInfo.propertyId,
        callBackId : this.userInfo.callBackId,
        roomId:this.userInfo.roomId,
        userName:this.userInfo.ownerName,
        ...this.solveForm,
        ...this.cpRecord
      }      
      this.hide()
      let result = await postSoloveProblemBack(param)
      if(result.success) {

      }
    },        
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
</style>
