<template>
  <el-container>
    <el-header class="header">
      <div class="leftTit">成员管理</div>
      <div class="rightBtn">
        <el-button type="primary" @click="addMember1">新增成员</el-button>
      </div>
    </el-header>
    <el-main>
      <div class="remindRemarksBox">
        <p>温馨提示：</p>
        <p>超时提醒: 用于物业监控中心账号退出后超时未登录，报警事件超时未解决的短信提醒机制，建议监控人员开启提醒。</p>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData && tableData.depts" style="width: 100%">
          <el-table-column prop="id" label="ID" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="account" label="登录账号" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="name" label="负责人姓名" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="mobile" label="联系电话" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="roleName" label="所属权限" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column prop="remind" label="超时提醒" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ scope.row.remind == null || scope.row.remind == 0 ? '未开启' : '已开启' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="loginStatusTime" label="最近登录时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.loginStatusTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="loginOutTime" label="最近退出时间" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p>
                {{ formatDate(scope.row.loginOutTime) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button @click.native.prevent="addRemind(scope.$index, tableData)" type="text" size="small">
                <p v-if="scope.row.remind == null || scope.row.remind == 0">开启提醒</p>
                <p v-if="scope.row.remind == 1" :style="{ color: color }">关闭提醒</p>
              </el-button>
              <el-button @click.native.prevent="editRow(scope.$index, tableData)" type="text" size="small">
                编辑
              </el-button>
              <el-button
                v-if="scope.row.roleName != '超级管理员'"
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <!-- 打开新增成员弹框 -->
    <addMember
      :Ok="addMemberOK"
      :recordData="recordData"
      :titType="titType"
      :addMemberShow="addMemberShow"
      @closeAddMember="showAddMember"
    ></addMember>
    <!-- 打开新增成员弹框 -->
    <addMember
      :Ok="addMemberOK"
      :recordData="recordData"
      :titType="titType"
      :addMemberShow="addMemberShow"
      @closeAddMember="showAddMember"
    ></addMember>
  </el-container>
</template>
<script>
import addMember from '@/components/property/addMember'
import { queryMemberList, addMemberApi, updateMemberApi, delMemberApi, addTimeoutReminder } from '@/api/property.js'

export default {
  props: ['addMember'],
  components: {
    addMember: addMember
  },
  data() {
    return {
      addMemberShow: false,
      tableData: [],
      recordData: {},
      titType: '',
      query: {
        page: 1,
        pageSize: 10
      },
      data: {
        remind: ''
      },
      color: '#7B8D8D'
    }
  },
  created() {
    this.init()
  },
  methods: {
    //初始化表格
    async init() {
      let result = await queryMemberList(this.query)
      if (result.success) {
        this.tableData = result.data
      }
    },
    //新增/编辑成员确定按钮
    async addMemberOK(data, id) {
      if (!id) {
        let result = await addMemberApi(data)
        if (result.success) {
          this.$message.success('操作成功')
          this.init()
        } else {
          this.$message.error(result.message)
        }
      } else {
        let result = await updateMemberApi(data, id)
        if (result.success) {
          this.$message.success('操作成功')
          this.init()
        } else {
          this.$message.error(result.message)
        }
      }
    },
    // 开启消息提醒
    async addRemind(index) {
      const userInfoData = this.tableData.depts[index]
      const userId = userInfoData.id
      const remind = userInfoData.remind
      if (remind == 1) {
        this.data.remind = 0
        let result = await addTimeoutReminder(this.data, userId)
        if (result.success) {
          this.$message.success('关闭开启超时提醒')
          this.init()
        }
      } else if (remind == 0 || remind == null) {
        this.data.remind = 1
        let result = await addTimeoutReminder(this.data, userId)
        if (result.success) {
          this.$message.success('成功开启超时提醒')
          this.init()
        }
      }
    },
    //删除
    async delRow(i) {
      const id = this.tableData.depts[i].id
      let result = await delMemberApi(id)
      if (result.success) {
        this.init()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }
    },
    editRow(index) {
      this.titType = '编辑'
      this.recordData = this.tableData.depts[index]
      this.addMemberShow = true
    },
    deleteRow(index) {
      this.$confirm('确定删除成员吗？删除后该成员将不可登录终端设备', '删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow(index)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showAddMember() {
      this.addMemberShow = false
    },
    addMember1() {
      this.titType = '新增'
      this.addMemberShow = true
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.list {
  padding: 16px;
  .pageBox {
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
.remindRemarksBox {
  border: 0.5px solid #f8d2d2;
  background: #fdf4f4;
  margin: 0 16px;
  padding: 16px;
  border-radius: 4px;
  p {
    color: #dd2424;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
}
</style>
