<template>
  <el-container>
    <el-dialog title="编辑小区信息" :visible.sync="editProperty" width="60%" @close="close">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="物业名称" prop="name">
              <el-input v-model="ruleForm.propertyName"></el-input>
            </el-form-item>
            <el-form-item label="所属地区" prop="name">
              <el-input v-model="ruleForm.propertyArea"></el-input>
            </el-form-item>
            <el-form-item label="负责人姓名" prop="name">
              <el-input v-model="ruleForm.contactPerson"></el-input>
            </el-form-item>
            <el-form-item label="营业执照" prop="name">
              <el-upload
                class="upload-demo"
                :action="`http://kwangda.com:8090/propertyReview/upload/file/${ruleForm.propertyName}`"
                :before-upload="beforeFileUp"
                :on-success="fileSuced"
                multiple
                :limit="1"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="法人姓名" prop="name">
              <el-input v-model="ruleForm.legalRepresentative"></el-input>
            </el-form-item>
            <el-form-item label="建设时间" prop="name">
              <el-date-picker style="width: 100%" v-model="ruleForm.constructionTime" type="year" placeholder="选择年">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="总栋楼" prop="name">
              <el-input v-model="ruleForm.generalBuilding"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开发商" prop="name">
              <el-input v-model="ruleForm.developers"></el-input>
            </el-form-item>
            <el-form-item label="详细地址" prop="name">
              <el-input v-model="ruleForm.propertyAddress"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="name">
              <el-input v-model="ruleForm.contactPhone"></el-input>
            </el-form-item>
            <el-form-item label="信用代码" prop="name">
              <el-input v-model="ruleForm.businessLicenseId"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="name">
              <el-input v-model="ruleForm.propertyPhone"></el-input>
            </el-form-item>
            <el-form-item label="占地面积" prop="name">
              <el-input v-model="ruleForm.areaCovered"></el-input>
            </el-form-item>
            <el-form-item label="总户数" prop="name">
              <el-input v-model="ruleForm.totalHouseholds"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="行政用房" prop="adminHousing">
            <el-checkbox-group v-model="ruleForm.adminHousing">
              <el-checkbox label="1">办公室</el-checkbox>
              <el-checkbox label="2">门卫室</el-checkbox>
              <el-checkbox label="3">监控室</el-checkbox>
              <el-checkbox label="4">会议室</el-checkbox>
              <el-checkbox label="5">培训室</el-checkbox>
              <el-checkbox label="6">接待室</el-checkbox>
              <el-checkbox label="7">库房</el-checkbox>
              <el-checkbox label="8">其它</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['editProperty', 'ppInfo', 'updateInfo'],
  data() {
    return {
      ruleForm: {
        accountId: '',
        adminHousing: [],
        areaCovered: '',
        auditState: '',
        businessLicense: '',
        businessLicenseId: '',
        constructionTime: '',
        contactPerson: '',
        contactPhone: '',
        createTime: '',
        developers: '',
        generalBuilding: '',
        legalRepresentative: '',
        password: '',
        propertyAddress: '',
        propertyPhone: '',
        remarks: '',
        totalHouseholds: '',
        villageName: ''
      },
      fileList: [
        {
          name: 'food.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        },
        {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
      ],
      checkList: ['选中且禁用', '复选框 A'],
      input: '',
      value3: '',
      value: [],
      form: {
        record: ''
      }
    }
  },
  watch: {
    ppInfo(n, o) {
      this.ruleForm = n
    }
  },
  methods: {
    close() {
      this.$emit('closeEditProperty')
    },
    ok() {
      this.updateInfo(this.ruleForm)
      this.close()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleChange(value) {
      console.log(value)
    },
    beforeFileUp() {
      if (!this.ruleForm.propertyName) {
        this.$message.error('请先输入物业名称')
        return false
      }
    },
    fileSuced(res) {
      if (res.success) {
        this.$message.success('上传成功')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .address {
    width: 100%;
    height: 40px;
  }
  .year {
    width: 100%;
  }
  .el-checkbox {
    padding: 8px 0;
  }
}
</style>
