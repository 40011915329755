<template>
  <el-container>
    <el-dialog
      title="电话详情11"
      v-el-drag-dialog
      :visible.sync="isShow"
      width="50%"
      @close="close"
      :modal="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="content">
        <!-- 报警信息 -->
        <div class="c_warnBox">
          <!-- 未解决 -->
          <!-- <div class="c_w_inner c_w_alertBox" v-if="callWarnShow == false">
            <div class="c_w_i_titBox">
              <span
                >{{ callInfo.floorName }}号楼{{ callInfo.unitName }}单元{{ callInfo.layerName }}层{{
                  callInfo.roomName
                }}</span
              >
              <span> - </span>
              <span>{{ formatDate(callInfo.createTime) }}</span>
            </div>
            <div class="c_w_i_titBox">
              <span>来电未接听</span>
            </div>
          </div> -->
          <!-- 已解决 -->
          <div class="c_w_inner c_w_securityBox" v-if="callWarnShow == true">
            <el-row class="c_b_inner">
              <div class="c_b_i_tit">通话记录</div>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>来电电话：</span>
                  <span>{{ callInfo.incomingCall }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>被叫电话：</span>
                  <span>{{ callInfo.calledTelephone }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>来电开始时间：</span>
                  <span>{{ formatDate(callInfo.callStartTime) }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>来电结束时间</span>
                  <span>{{ formatDate(callInfo.callEndTime) }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>通话记录：</span>
                  <span>播放录音</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>处理状态：</span>
                  <span>{{ callInfo.processingStatus }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>处理记录：</span>
                  <span>{{ callInfo.processingRecord }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="c_b_items">
                  <span>解决方：</span>
                  <span>{{ callInfo.resolver }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 基本信息 -->
        <div class="c_baseBox">
          <el-row class="c_b_inner">
            <div class="c_b_i_tit">基本信息</div>
            <el-col :span="8">
              <div class="c_b_items">
                <span>业主姓名：</span>
                <span>{{ userInfo.name }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>联系电话：</span>
                <span>{{ userInfo.ownerPhone }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>开通方式：</span>
                <span>{{
                  userInfo.openMethod == '0'
                    ? '自主开通'
                    : userInfo.openMethod == '1'
                    ? '物业开通'
                    : '平台开通'
                }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>服务状态：</span>
                <span>{{ (userProfileInfo.serviceStatus = '0' ? '待安装' : '服务正常') }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="c_b_items">
                <span>申请时间：</span>
                <span>{{ userInfo.createTime }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 家庭成员 -->
        <div class="c_familyBox">
          <div class="c_f_tit">家庭成员列表</div>
          <div class="c_f_content">
            <el-row :gutter="8">
              <el-col :span="6">
                <div class="c_f_c_items">
                  <div class="c_f_c_i_tag">业主</div>
                  <div class="avatar">
                    <img src="../../../assets/img/server_icon.svg" />
                  </div>
                  <div class="c_f_c_i_name">
                    <span :style="{ 'padding-right': '16px' }">桑先生</span>
                    <span>28岁</span>
                  </div>
                  <div class="c_f_c_i_dec">无慢性病</div>
                  <div class="c_f_c_i_dec">能力完好</div>
                  <div class="c_f_c_i_btn">
                    <div class="c_f_c_i_urgent">紧急联系</div>
                    <div class="c_f_c_i_call">拨打电话</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="c_f_c_items">
                  <div class="c_f_c_i_tag">父子</div>
                  <div class="avatar">
                    <img src="../../../assets/img/server_icon.svg" />
                  </div>
                  <div class="c_f_c_i_name">
                    <span :style="{ 'padding-right': '16px' }">桑先生</span>
                    <span>56岁</span>
                  </div>
                  <div class="c_f_c_i_dec">
                    <span>慢性病标签</span>
                    <span>慢性病标签</span>
                  </div>
                  <div class="c_f_c_i_dec">能力完好</div>
                  <div class="c_f_c_i_btn">
                    <div class="c_f_c_i_urgent">紧急联系</div>
                    <div class="c_f_c_i_call">拨打电话</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="c_f_c_items">
                  <div class="c_f_c_i_tag">母子</div>
                  <div class="avatar">
                    <img src="../../../assets/img/server_icon.svg" />
                  </div>
                  <div class="c_f_c_i_name">
                    <span :style="{ 'padding-right': '16px' }">桑先生</span>
                    <span>28岁</span>
                  </div>
                  <div class="c_f_c_i_dec">无慢性病</div>
                  <div class="c_f_c_i_dec">能力完好</div>
                  <div class="c_f_c_i_btn">
                    <div class="c_f_c_i_urgent">紧急联系</div>
                    <div class="c_f_c_i_call">拨打电话</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" >
        <div :class="type == 0 ? 'shake':''">
          <el-button v-if="type == 0" type="success" @click="handOn()">接听</el-button>
        </div>
        <el-button v-if="type == 1" type="dangerous" @click="handOff()">挂断{{m}}:{{s}}</el-button>
        <el-button v-if="type == 2" type="dangerous" @click="hide()">未接听{{phone}}</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
import {queryUserProfile} from '@/api/call.js'

export default {
  props: ['callWarn', 'callDetails','userInfo','type','phone'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      isShow:false,
      callWarnShow: '',
      callInfo: {},
      // 基本信息
      userProfileInfo: {},
      m:'00',
      s:0,
      timer:null,
    }
  },
  watch: {
    callDetails(n, o) {
      this.callInfo = n
      // console.log("------n-----", n);
      if (n.resolver != null) {
        this.callWarnShow = true
      } else {
        this.callWarnShow = false
      }
      this.getUserProfile()
    }
  },
  mounted(){
    if(!this.userInfo){return}
    this.getUserProfile(this.userInfo.roomId)
  },
  methods: {
    close() {
      this.$emit('closeCallWarn')
    },
    // 获取设备报警所属服务信息
    async getUserProfile(roomId) {
      let result = await queryUserProfile(roomId)
      if (result.success) {
        this.userProfileInfo = result.data
      }
    },
    show(){
      this.isShow = true
    },
    hide(){
      this.isShow = false,
      this.remove()
    },
    handOn(){ //接听电话
      this.type = 1
      this.globalCall.handOn()
      this.cpRecord.callStartTime = this.formatDate(new Date()) //记录通话开始时间
      this.cpRecord.incomingCall = this.phone
      this.clcTimer()
    },  
    handOff(){ //挂断电话
      this.globalCall.handOff()
      this.cpRecord.callEndTime = this.formatDate(new Date()) //记录通话结束时间
      // this.cpRecord.processingTime = (new Date(this.cpRecord.callEndTime).getTime() - new Date(this.cpRecord.callStartTime).getTime() )/1000  //通话时长;单位s秒
      window.cp.createDlgSolve({userInfo:this.userInfo,type:1}).show()//type:1 来电; 2:回访
      console.log("window.cp.processionTime::",window.cp.processionTime)
      clearInterval(this.timer)
    },
    clcTimer () {
      let sCount = 0
      let mCount = 0
      this.timer = setInterval(()=>{
        sCount++
        this.s = sCount
        if(sCount == 60) {
          sCount = 0
          mCount++ 
          this.m = mCount
        }
      },1000)
    }
  }
}
</script>
<style lang="less" scoped>
.shake{
  animation: shake 1000s infinite; 
}
@keyframes shake {
  10%,90%{transform: translate3d(-1px,0,0);}
  20%,80%{transform: translate3d(+2px,0,0);}
  30%,70%{transform: translate3d(-4px,0,0);}
  40%,60%{transform: translate3d(+4px,0,0);}
  50%{transform: translate3d(-4px,0,0);}

}
.content {
  max-height: 500px;
  overflow: auto;
}
.c_w_inner {
  padding: 16px;
  margin-bottom: 16px;
  .c_w_i_titBox {
    padding: 8px 0;
    span {
      font-size: 32px;
      font-weight: 500;
      color: #fff;
    }
  }
}
.c_w_alertBox {
  background: #e55e5e;
}
.c_w_securityBox {
  background: #d2f8e5;
}
.c_baseBox {
  padding: 16px;
  border: 1px solid #c7cfcf;
}
.c_b_i_tit {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}
.c_b_items {
  padding: 8px 0;
}
.c_f_tit {
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0;
}
.c_f_c_items {
  border: 1px solid #c7cfcf;
  text-align: center;
  .c_f_c_i_tag {
    position: absolute;
    background: #09bebe;
    padding: 4px 16px;
    color: #fff;
  }
  .avatar {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .c_f_c_i_name {
    font-size: 16px;
    font-weight: 500;
    line-height: 44px;
  }
  .c_f_c_i_dec {
    padding-bottom: 16px;
    font-size: 12px;
    span {
      color: #dd2424;
      padding: 2px 4px;
      background: #f8d2d2;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
  .c_f_c_i_btn {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 16px 16px;
    .c_f_c_i_urgent {
      color: #dd2424;
      width: 44%;
      line-height: 32px;
      border-radius: 4px;
      background: #fbe9e9;
    }
    .c_f_c_i_call {
      color: #09be64;
      width: 44%;
      line-height: 32px;
      background: #e9fbf2;
      border-radius: 4px;
    }
  }
}
</style>
