<template>
  <el-container>
    <el-header class="header">值班管理</el-header>
    <el-main>
      <!-- 当前值班 - 第一组 -->
      <div class="current_group">
        <div class="current_group_info">
          <div class="current_info">当前值班 - 第一组</div>
          <el-row :gutter="8">
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <div class="grid_tit">早班</div>
                <div class="grid_de">06:00 - 14:00</div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <div class="grid_tit">
                  <div>张三</div>
                  <el-button type="success" size="mini" @click="call">拨打</el-button>
                </div>
                <div class="grid_de">137 1834 5029</div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <div class="grid_tit">
                  <div>李四</div>
                  <el-button type="success" size="mini" @click="call">拨打</el-button>
                </div>
                <div class="grid_de">137 1834 5029</div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <div class="grid_tit">
                  <div>王五</div>
                  <el-button type="success" size="mini" @click="call">拨打</el-button>
                </div>
                <div class="grid_de">137 1834 5029</div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <div class="grid_tit">
                  <div>赵六</div>
                  <el-button type="success" size="mini" @click="call">拨打</el-button>
                </div>
                <div class="grid_de">137 1834 5029</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-row :gutter="8" class="setBox">
        <!-- 设置班次 -->
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <div class="grid_titBox">
              <div>设置班次</div>
              <div>
                <el-button type="primary" size="mini" @click="OpenAddShift">新增班次</el-button>
              </div>
            </div>
            <div class="shift_inner">
              <div class="shift_inner_top">
                <div>早班</div>
                <div class="shift_inner_top_btn">
                  <span @click="OpenAddShift">
                    <i class="el-icon-edit"></i>
                  </span>
                  <span @click="deleteRow">
                    <i class="el-icon-delete"></i>
                  </span>
                </div>
              </div>
              <div>06:00 - 14:00</div>
            </div>
            <div class="shift_inner" style="background: linear-gradient(135deg, #ffc355, #e47040)">
              <div class="shift_inner_top">
                <div>中班</div>
                <div class="shift_inner_top_btn">
                  <span @click="OpenAddShift">
                    <i class="el-icon-edit"></i>
                  </span>
                  <span @click="deleteRow">
                    <i class="el-icon-delete"></i>
                  </span>
                </div>
              </div>
              <div>06:00 - 14:00</div>
            </div>
            <div class="shift_inner" style="background: linear-gradient(135deg, #0ca076, #056767)">
              <div class="shift_inner_top">
                <div>晚班</div>
                <div class="shift_inner_top_btn">
                  <span @click="OpenAddShift">
                    <i class="el-icon-edit"></i>
                  </span>
                  <span @click="deleteRow">
                    <i class="el-icon-delete"></i>
                  </span>
                </div>
              </div>
              <div>06:00 - 14:00</div>
            </div>
          </div>
        </el-col>
        <!-- 设置班组 -->
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <div class="grid_titBox">
              <div>设置班组</div>
              <div>
                <el-button type="primary" size="mini" @click="OpenAddDuty">新增班组</el-button>
              </div>
            </div>
            <div class="shift_inner" style="color: #202626; background: #ffffff; border: 1px solid #c7cfcf">
              <div class="shift_inner_top">
                <div>第一组</div>
                <div class="shift_inner_top_btn">
                  <span @click="OpenAddDuty">
                    <i class="el-icon-edit"></i>
                  </span>
                  <span @click="deleteRow">
                    <i class="el-icon-delete"></i>
                  </span>
                </div>
              </div>
              <div>4人</div>
            </div>
            <div class="shift_inner" style="color: #202626; background: #ffffff; border: 1px solid #c7cfcf">
              <div class="shift_inner_top">
                <div>第二组</div>
                <div class="shift_inner_top_btn">
                  <span @click="OpenAddDuty">
                    <i class="el-icon-edit"></i>
                  </span>
                  <span @click="deleteRow">
                    <i class="el-icon-delete"></i>
                  </span>
                </div>
              </div>
              <div>4人</div>
            </div>
            <div class="shift_inner" style="color: #202626; background: #ffffff; border: 1px solid #c7cfcf">
              <div class="shift_inner_top">
                <div>第三组</div>
                <div class="shift_inner_top_btn">
                  <span @click="OpenAddDuty">
                    <i class="el-icon-edit"></i>
                  </span>
                  <span @click="deleteRow">
                    <i class="el-icon-delete"></i>
                  </span>
                </div>
              </div>
              <div>4人</div>
            </div>
          </div>
        </el-col>
        <!-- 设置排班 -->
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <div class="grid_titBox">
              <div>设置排班</div>
              <div>
                <el-button type="primary" size="mini">历史排版</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 隐私电话弹框 -->
      <privacyCall :privacyCall="privacyCallShow" @closePrivacyCall="showPrivacyCall"></privacyCall>
      <!-- 设置班次弹框 -->
      <addShift :addShift="addShiftShow" @closeAddShift="showAddShift"></addShift>
      <!-- 新增班组弹框 -->
      <addDuty :addDuty="addDutyShow" @closeAddDuty="showAddDuty"></addDuty>
    </el-main>
  </el-container>
</template>
<script>
import PrivacyCall from '@/components/public/privacyCall'
import AddShift from '@/components/property/addShift'
import AddDuty from '@/components/property/addDuty'
export default {
  props: ['privacyCall', 'addShift', 'addDuty'],
  components: {
    privacyCall: PrivacyCall,
    addShift: AddShift,
    addDuty: AddDuty
  },
  data() {
    return {
      privacyCallShow: false,
      addShiftShow: false,
      addDutyShow: false
    }
  },
  methods: {
    showPrivacyCall() {
      this.privacyCallShow = false
    },
    call() {
      this.privacyCallShow = true
    },
    showAddShift() {
      this.addShiftShow = false
    },
    OpenAddShift() {
      this.addShiftShow = true
    },
    showAddDuty() {
      this.addDutyShow = false
    },
    OpenAddDuty() {
      this.addDutyShow = true
    },
    deleteRow(index) {
      this.$confirm('确定删除成员吗？删除后该成员将不可登录终端设备', '删除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.current_group {
  margin: 16px;
  .current_group_info {
    padding: 16px;
    background: #ffffff;
    border: 1px solid #c7cfcf;
    .current_info {
      font-weight: 500;
      padding-bottom: 16px;
    }
    .grid-content {
      padding: 16px;
      background: #ffffff;
      border: 1px solid #c7cfcf;
      height: 55px;
      .grid_tit {
        font-weight: 500;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
.setBox {
  padding: 0 16px;
  .grid-content {
    background: #ffffff;
    border: 1px solid #c7cfcf;
    height: calc(100vh - 350px);
    padding: 16px;
    .grid_titBox {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      align-items: center;
      font-weight: 500;
    }
    .shift_inner {
      padding: 16px;
      color: #ffffff;
      background: #24dddd;
      margin-bottom: 8px;
      .shift_inner_top {
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        .shift_inner_top_btn {
          font-size: 20px;
          span {
            margin-left: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
