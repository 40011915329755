<template>
  <el-container>
    <el-dialog title="批量新增" :visible="batchShow" width="60%" @close="close" class="abow_dialog">
      <section>
        <h3>一级(号楼)</h3>
        <el-table :data="tableFloor" style="width: 100%" :border="true">
          <el-table-column prop="option" label="执行" align="center"> </el-table-column>
          <el-table-column prop="name" label="变量" align="center">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.name" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="单位" align="center"> </el-table-column>
        </el-table>
      </section>
      <section>
        <h3>二级(单元)</h3>
        <el-table :data="tableUnit" style="width: 100%" :border="true">
          <el-table-column prop="option" label="执行" align="center"> </el-table-column>
          <el-table-column prop="name" label="变量" align="center">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.name" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="单位" align="center"> </el-table-column>
        </el-table>
      </section>
      <section>
        <h3>三级(层)</h3>
        <el-table :data="tableLayer" style="width: 100%" :border="true">
          <el-table-column prop="option" label="执行" align="center"> </el-table-column>
          <el-table-column prop="name" label="变量" align="center">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.name" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="单位" align="center"> </el-table-column>
        </el-table>
      </section>
      <section>
        <h3>四级(房号)</h3>
        <el-table :data="tableRoom" style="width: 100%" :border="true">
          <el-table-column prop="option" label="执行" align="center"> </el-table-column>
          <!-- <el-table-column  prop="fix1" label="固定值1(选填)" align="center" >
                    <template slot-scope="scope">
                        <el-input size="small" v-model="scope.row.fix1" placeholder="请输入"></el-input>
                    </template>
                </el-table-column>                 -->
          <el-table-column prop="name1" label="变量1(楼层)" align="center">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.name1" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fix" label="固定值" align="center">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.fix" placeholder="请输入" disabled></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="name2" label="变量2(房号)" align="center">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.name2" placeholder="请输入"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="单位" align="center"> </el-table-column>
        </el-table>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="besure">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
  <script>
import { batchSave } from '@/api/property.js'

export default {
  props: ['batchShow','init'],
  data() {
    return {
      tableFloor: [
        { option: '开始', name: '', type: '号楼' },
        { option: '结束', name: '', type: '号楼' }
      ],
      tableUnit: [
        { option: '开始', name: '', type: '单元' },
        { option: '结束', name: '', type: '单元' }
      ],
      tableLayer: [
        { option: '开始', name: '', type: '层' },
        { option: '结束', name: '', type: '层' }
      ],
      tableRoom: [
        { option: '开始', fix1: '', name1: '', fix: '0', name2: '', type: '室' },
        { option: '结束', fix1: '', name1: '', fix: '0', name2: '', type: '室' }
      ],
      floorList: [], //楼号列表
      unitList: [], //单元列表
      layerList: [], //楼层列表

      totalList: [] //所有数据
    }
  },
  methods: {
    // 楼号 单元、楼层计算
    NumClc(data, list) {
      const b = parseInt(data[0].name) //开始楼号
      const o = parseInt(data[1].name)
      for (let i = b; i < o + 1; i++) {
        list.push(i)
      }
    },
    //房间号计算
    roomNumClc(data) {
      const isHas0 = data[0].name1 == '01' ? true : false
      let list = [],
        roomList = []
      const layerB = parseInt(data[0].name1) //开始楼层
      const layerO = parseInt(data[1].name1) //结束楼层

      const b = parseInt(data[0].name2) //开始房间号
      const o = parseInt(data[1].name2) //结束房间号
      //判断是否低楼层
      const isLowLayer = (k) => {
        if (isHas0) {
          //如果是四位房间号如：0102
          if (k < 10) {
            return '0' + k
          } else {
            return parseInt(k)
          }
        } else {
          return parseInt(k)
        }
      }
      for (let i = b; i < o + 1; i++) {
        roomList.push(i)
      }
      for (let k = layerB; k < layerO + 1; k++) {
        list.push({
          layerName: k,
          children:
            roomList &&
            roomList.map((d) => ({
              roomName: isLowLayer(k) + '0' + d
            }))
        })
      }
      return list
    },
    //合并数据
    concatClc() {
      const list = []
      const fl = this.floorList
      const ul = this.unitList
      const ll = this.layerList
      fl &&
        fl.map((d) => {
          list.push({
            floorName: d,
            children:
              ul &&
              ul.map((d) => ({
                unitName: d,
                children: this.roomNumClc(this.tableRoom)
                // children:ll && ll.map(d =>({
                //     layerName:d,
                //     name:d,
                //     children:[]
                // }))
              }))
          })
        })
      return list
    },
    reset() {
      this.floorList = []
      this.unitList = []
      this.layerList = []
    },
    besure() {
      this.reset()
      this.NumClc(this.tableFloor, this.floorList)
      this.NumClc(this.tableUnit, this.unitList)
      this.NumClc(this.tableLayer, this.layerList)
      // this.roomNumClc(this.tableRoom)
      this.addSave(this.concatClc())
    },
    //保存
    async addSave(data) {
      let result = await batchSave(data)
      if (result.success) {
        this.$message.success('操作成功1')
        this.init()
        this.close()
      }
    },
    close() {
      this.$emit('closeBatch')
    }
  }
}
</script>
  <style lang="less" scoped>
.content {
  color: #202626;
  text-align: center;
  .number {
    font-size: 40px;
    font-weight: 500;
    padding: 16px 0;
  }
}
</style>
  