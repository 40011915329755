//1.创建传入的组件实例
//2.挂载它从而生成dom结构
//3.将生成的dom结构追加到body上
//4.一个淘汰机制：将不需要的时候组件实例销毁，防止内存泄漏

import Vue from "vue";
import Component from './warnSolve.vue'

let instance = null
function createCall(props) {
    //1.创建传入的组件实例 有两种方式 Vue.extend({})/new Vue({})
    const vm = new Vue({
        render(h){  //这个函数的作用是 返回虚拟dom;
            //h即是createElement(tag,data,children)
            return h(Component,{props})
        }
        //2.只挂载，不设置宿主元素，意思是执行初始化的过程，但没有追加dom操作;把虚拟dom转化为真实dom
    }).$mount();

    3.//将生成的dom追加到body上
    document.body.appendChild(vm.$el)
    4.//淘汰 - 先过去组件实例
    const comp = vm.$children[0]
    comp.remove = () => {
        //移除dom
        document.body.removeChild(vm.$el)
        //销毁组件
        vm.$destroy()
        instance = null
    };
    return comp
}

export default  function create (props) {
    if(!instance) {
        instance = createCall(props)
    }
    return instance
}


