import Vue from 'vue'

import {	
    ubox_dialnum,
	ubox_hook_off,
	ubox_hook_on,
	ubox_reject_on,
    ubox_RecordFile
} from './js/uboxweb'


Vue.prototype.globalCall ={
    callPhone : ubox_dialnum, //拨打电话
    handOn : ubox_hook_off, // 摘机接听
    handOff : ubox_hook_on, // 挂机
    callRefuse : ubox_reject_on, // 拒接
    recordFile : ubox_RecordFile,//开始录音
}


